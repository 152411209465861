import { css } from "@emotion/react";

export const LikerScaleQuestionTextStyles = ({ units: { survey } }) => css`
  padding: 0.5rem 0rem;

  .question {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    min-height: 2.5rem;

    .question-tag {
      color: ${survey.textColor};
      background-color: ${survey.background};
      border-radius: 50%;
    }
  }
`;

export const baseLikertScaleContainer = ({ units: { survey } }) => css`
  display: flex;
  margin: 0;
  padding-inline-start: 0;

  .likert-option {
    list-style-type: none;

    &.read-only {
      .likert-answer {
        & > div label {
          cursor: not-allowed;
        }
      }
    }

    &.disabled {
      .likert-answer {
        & > div label::before {
          border: 1px solid ${survey.disabled};
        }

        &:before,
        &:after {
          background-color: ${survey.disabled};
        }
      }
    }

    .likert-answer {
      position: relative;

      & > div {
        display: flex;
      }
    }

    &:first-of-type {
      .likert-answer:before {
        display: none;
      }
    }

    &:last-of-type {
      .likert-answer:after {
        display: none;
      }
    }

    .option-label {
      margin: 0.5rem 0.5rem 0;
      cursor: default;
    }
  }
`;

export const likertScaleContainerDesktop = ({ units: { survey } }) => css`
  flex-direction: row;
  justify-content: space-between;

  .likert-option {
    display: flex;
    flex-direction: column;
    flex: 1;
    word-break: break-word;
    text-align: center;
    padding: 2rem 0;
    margin-inline: 0rem;

    .likert-answer {
      display: flex;
      justify-content: center;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: ${survey.border};
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        width: calc(50% - 12px);
        inset-inline-start: 0%;
      }

      &:after {
        width: calc(50% - 8px);
        inset-inline-start: calc(50% + 8px);
      }

      & > div {
        height: 2.5rem;
      }

      input[type="radio"] {
        display: none;

        & + label {
          padding-inline-start: 0;
        }
      }
    }
  }
`;

export const likertScaleContainerMobile = ({ units: { survey } }) => css`
  flex-direction: column;

  .likert-option {
    margin-inline: 1.5rem;

    .likert-answer {
      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: ${survey.border};
        width: 2px;
        height: 50%;
        inset-inline-start: 10px;
      }

      &:before {
        height: calc(50% - 10px);
        top: 0;
      }

      &:after {
        height: calc(50% - 10px);
        top: calc(50% + 10px);
      }
    }
  }
`;
