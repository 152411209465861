import React from "react";
import { filePreview } from "@utils/helpers";
import { Answer } from "types/entities";

export const previewContent = (selectedAnswer: Answer | null): JSX.Element => {
  if (!selectedAnswer) return <></>;

  const { type, file, text } = selectedAnswer;

  switch (type) {
    case "text":
      return <div dangerouslySetInnerHTML={{ __html: text.html }} />;
    case "file":
      return filePreview(file);
    default:
      return <></>;
  }
};
