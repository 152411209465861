import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { GlobalStyleVariables } from "@styles";
const { unitsMaxWidth } = GlobalStyleVariables;

export const assignmentContainer = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: ${unitsMaxWidth};
  margin: auto;

  .assignment-description {
    margin: 1rem 0 2rem;

    ${mq["md"]} {
      margin: 2rem 0;
    }

    ${mq["lg"]} {
      margin: 0 0 2rem;
    }
  }

  .reply-options-container {
    text-align: center;
  }

  .submitted-answer {
    text-align: center;
    margin: 2rem 0;
  }
`;
