import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { tocListContainer } from "./styles";

export type ListProps = FC<{
  title?: string | JSX.Element;
}>;

const List: ListProps = ({ title, children }) => (
  <dl css={tocListContainer} className="list-container">
    {title && (
      <dt className="list-title-container">
        <Text fontSize="md" weight="700">
          {title}
        </Text>
      </dt>
    )}
    {children}
  </dl>
);

export default List;
