import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const replyOptionsContainer = () => css`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 0.5rem;

  ${mq["sm"]} {
    gap: 2rem;
  }

  .reply-option {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 0.5rem;
    }

    svg {
      fill: currentColor;
    }
  }
`;

export const freeTextAnswerContainer = css`
  .actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    button[type="submit"] {
      margin-bottom: 1rem;
    }
  }
`;

export const fileUploadContainer = css`
  display: flex;
  align-items: center;
  flex-direction: column;

  .actions-container {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    button[type="submit"] {
      margin-bottom: 1rem;
    }
  }
`;

export const submittedAnswerContainer = css`
  margin-bottom: 1.5rem;

  .header {
    margin: 0 0 1rem;
    text-align: center;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .file-container {
    height: 400px;
    max-width: 600px;
    margin: auto;
  }

  .image-container {
    height: 400px;
    margin: auto;

    ${mq["md"]} {
      max-width: 80%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .file-card-container {
    max-width: 300px;
    margin: auto;
  }

  .audio-player {
    width: 100%;
    max-width: 500px;
    height: 75px;

    & > div {
      height: inherit;
    }
  }

  .video-player {
    max-width: 60%;
  }

  .file-proccessing-wrapper {
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    text-align: center;

    img {
      width: 100%;

      ${mq["md"]} {
        width: 60%;
        max-width: 700px;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      ${mq["md"]} {
        width: 60%;
        max-width: 700px;
      }

      .title {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export const instructorReplyContainer = css`
  .grade-wrapper {
    display: flex;
    margin-bottom: 0.5rem;

    span {
      margin-inline-start: 0.5rem;
    }
  }

  .reply-wrapper {
    margin-bottom: 1rem;

    .header {
      margin-bottom: 0.5rem;
    }
  }
`;
