import React, { ReactElement, useState } from "react";
import { ActionDrawer } from "@components";
import { useApplyTranslations } from "@hooks";
import { Controller, FieldValues, Path } from "react-hook-form";
import { BranchDrawersProps, BranchPropertiesNames } from "@views/Branches/types";
import { Text, Input, ToggleSwitch, Select, InputError } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { toggleSwitchDescription } from "@views/Branches/styles";
import { Divider } from "@components/ReusableComponents";
import { getCurrencySymbol } from "@utils/helpers/currency";
import { DISALLOWED_KEYS } from "@views/Categories/constants";
import { subscriptionIntervalOptions } from "@views/Branches/constants";
import { SelectOption } from "types/common";
import { TALENT_LMS_HELP_LINKS } from "@constants/staticLinks";

type SubscriptionDrawerProps<T extends FieldValues> = BranchDrawersProps<T> & {
  ecommerceCurrency: string;
};

const SubscriptionDrawer = <T extends FieldValues>({
  control,
  isDisabled,
  watch,
  resetField,
  toggleDrawer,
  ecommerceCurrency,
  errors,
  isOpen,
}: SubscriptionDrawerProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();
  const { BRANCH_SUBSCRIPTIONS_DOCUMENTATION } = TALENT_LMS_HELP_LINKS;
  const processor = watch?.(BranchPropertiesNames.BRANCH_ECOMMERCE_PROCESSOR as Path<T>);
  const activeSubscriptions = watch?.(
    BranchPropertiesNames.BRANCH_SUBSCRIPTION_HAS_ACTIVE as Path<T>,
  );
  const hasActiveSubscription = activeSubscriptions && activeSubscriptions > 0;
  const accessToPaidCoursesValue = watch?.(
    BranchPropertiesNames.BRANCH_SUBSCRIPTION_ENABLED as Path<T>,
  );
  const subscriptionFeeValue = watch?.(BranchPropertiesNames.BRANCH_SUBSCRIPTION_FEE as Path<T>);
  const subscriptionIntervalValue = watch?.(
    BranchPropertiesNames.BRANCH_SUBSCRIPTION_INTERVAL as Path<T>,
  );
  const freeFirstMonthValue = watch?.(
    BranchPropertiesNames.BRANCH_SUBSCRIPTION_FREE_MONTH as Path<T>,
  );

  const tempValues = {
    accessToPaidCourses: accessToPaidCoursesValue,
    subscriptionFee: subscriptionFeeValue,
    subscriptionInterval: subscriptionIntervalValue,
    freeFirstMonth: freeFirstMonthValue,
  };

  const [tempValuesState, setTempValues] = useState(tempValues);

  const isProcessorStripe = processor === "stripe";
  const isSubscriptionToggleDisabled =
    isDisabled || !isProcessorStripe || (accessToPaidCoursesValue && hasActiveSubscription);
  const isSubscriptionFeeDisabled = isDisabled || !accessToPaidCoursesValue;
  const shouldDisableSubscriptionFields = isSubscriptionFeeDisabled || isSubscriptionToggleDisabled;

  const headerTitle = t("general.subscription");
  const handleClose = (): void => {
    const resetAccessToPaidCoursesValue = tempValuesState.accessToPaidCourses
      ? { defaultValue: tempValuesState.accessToPaidCourses }
      : undefined;
    const resetSubscriptionFeeValue = tempValuesState.subscriptionFee
      ? { defaultValue: tempValuesState.subscriptionFee }
      : undefined;
    const resetSubscriptionIntervalValue = tempValuesState.subscriptionInterval
      ? { defaultValue: tempValuesState.subscriptionInterval }
      : undefined;
    const resetFreeFirstMonthValue = tempValuesState.freeFirstMonth
      ? { defaultValue: tempValuesState.freeFirstMonth }
      : undefined;

    resetField?.(
      BranchPropertiesNames.BRANCH_SUBSCRIPTION_ENABLED as Path<T>,
      resetAccessToPaidCoursesValue,
    );
    resetField?.(
      BranchPropertiesNames.BRANCH_SUBSCRIPTION_FEE as Path<T>,
      resetSubscriptionFeeValue,
    );
    resetField?.(
      BranchPropertiesNames.BRANCH_SUBSCRIPTION_INTERVAL as Path<T>,
      resetSubscriptionIntervalValue,
    );
    resetField?.(
      BranchPropertiesNames.BRANCH_SUBSCRIPTION_FREE_MONTH as Path<T>,
      resetFreeFirstMonthValue,
    );

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled,
  };
  const onApply = (): void => {
    setTempValues(tempValues);
    toggleDrawer();
  };

  const subscriptionIntervalSelectOptions = subscriptionIntervalOptions.map((value) => ({
    label: t(`billingAndSubscription.optionsPage.${value}`),
    value: value,
  }));

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={(theme): SerializedStyles => toggleSwitchDescription(theme)}>
        <Controller
          name={BranchPropertiesNames.BRANCH_SUBSCRIPTION_ENABLED as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div>
              <ToggleSwitch
                labelAfter={t("branches.properties.subscription.accessToAllPaidCourses")}
                defaultChecked={Boolean(value)}
                isDisabled={isSubscriptionToggleDisabled}
                onChange={(): void => {
                  onChange(!value);
                }}
              />
              <div className="description-container">
                {!isProcessorStripe && (
                  <Text as="div" fontSize="sm" className="description">
                    {t("branches.properties.subscription.toggleDescription")}
                  </Text>
                )}
                {hasActiveSubscription && (
                  <Text
                    as="div"
                    fontSize="sm"
                    className="sub-description"
                    dangerouslySetInnerHTML={{
                      __html: t("branches.properties.subscription.toggleSubDescription"),
                    }}
                  />
                )}
              </div>
            </div>
          )}
        />
        <Divider />
        <div className="subscription-fee">
          <div className="subscription-input">
            <Controller
              name={BranchPropertiesNames.BRANCH_SUBSCRIPTION_FEE as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <>
                    <Input
                      type="number"
                      step={0.01}
                      label={t("branches.properties.subscription.subscriptionFee")}
                      size="md"
                      id="subscription-fee"
                      iconAfter={getCurrencySymbol(ecommerceCurrency)}
                      status={errors?.ecommerce?.subscription?.fee ? "error" : "valid"}
                      disabled={shouldDisableSubscriptionFields}
                      value={value ?? ""}
                      onChange={(e): void => {
                        const fee = e.target.value;
                        onChange(fee ? fee : null);
                      }}
                      onKeyDown={(e): void => {
                        if (DISALLOWED_KEYS.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.ecommerce?.subscription?.fee && (
                      <InputError style={{ margin: 0 }}>
                        {errors.ecommerce?.subscription?.fee?.message}
                      </InputError>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div className="subscription-input">
            <Controller
              name={BranchPropertiesNames.BRANCH_SUBSCRIPTION_INTERVAL as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => {
                const defaultValue = subscriptionIntervalSelectOptions.find(
                  (option) => option.value === value,
                );
                return (
                  <Select
                    id="subscription-interval"
                    options={subscriptionIntervalSelectOptions}
                    isDisabled={shouldDisableSubscriptionFields}
                    onChange={(option): void => {
                      const { value } = option as SelectOption;
                      onChange(value);
                    }}
                    label={t("billingAndSubscription.optionsPage.paid")}
                    defaultValue={defaultValue}
                  />
                );
              }}
            />
          </div>
        </div>
        <Controller
          name={BranchPropertiesNames.BRANCH_SUBSCRIPTION_FREE_MONTH as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div>
              <ToggleSwitch
                labelAfter={t("branches.properties.subscription.freeFirstMonth")}
                defaultChecked={Boolean(value)}
                isDisabled={shouldDisableSubscriptionFields}
                onChange={(): void => {
                  onChange(!value);
                }}
              />
              <div className="description-container">
                <Text
                  as="div"
                  fontSize="sm"
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: t("branches.properties.subscription.freeFirstMonthActivation", {
                      url: BRANCH_SUBSCRIPTIONS_DOCUMENTATION,
                    }),
                  }}
                />
              </div>
            </div>
          )}
        />
      </div>
    </ActionDrawer>
  );
};

export default SubscriptionDrawer;
