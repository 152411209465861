import React, { FC, useState, MouseEvent } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { components, MenuListProps } from "react-select";
import { customMenu } from "../styles";
import { AnswerOption } from "./MatchPairs";
import { useApplyTranslations } from "@hooks";

type MatchPairsMenuDropdownProps = {
  answeredQuestions: string[];
  moveToAnswersPool: (answer: string, index: number, answerIndex: number) => void;
  answerIndex: number;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

const MatchPairsMenuDropdown: FC<MatchPairsMenuDropdownProps & MenuListProps<AnswerOption>> = ({
  children,
  answeredQuestions,
  moveToAnswersPool,
  answerIndex,
  setIsMenuOpen,
  ...rest
}) => {
  const { t } = useApplyTranslations();
  const [showAnsweredQuestions, setShowAnsweredQuestions] = useState(false);
  const handleShowAnsweredQuestions = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowAnsweredQuestions((value) => !value);
  };
  const showText = answeredQuestions.some((item) => item && item !== undefined && item.length > 0);

  const handleAnswerChange = (item: string, index: number, answerIndex: number): void => {
    moveToAnswersPool(item, index, answerIndex);
    setIsMenuOpen(false);
  };

  return (
    <components.MenuList {...rest} css={customMenu}>
      <>
        {children}

        {showText && (
          <div className="answered-questions">
            <div className="btn-container">
              <Button variant="link" onClick={handleShowAnsweredQuestions}>
                {!showAnsweredQuestions
                  ? t("test.showSelectedAnswers")
                  : t("test.hideSelectedAnswers")}
              </Button>
            </div>

            <div className="answered-questions-container">
              {showAnsweredQuestions &&
                answeredQuestions.map((item, index) => (
                  <React.Fragment key={index}>
                    {Boolean(item) && (
                      <div
                        key={index}
                        className="answer"
                        onClick={(): void => handleAnswerChange(item, index, answerIndex)}
                      >
                        {item}
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </div>
        )}
      </>
    </components.MenuList>
  );
};

export default MatchPairsMenuDropdown;
