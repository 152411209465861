import React, { FC } from "react";
import Content, { ContentProps } from "./components/Content";
import Navbar, { NavbarProps } from "./components/Navbar";
import Sidebar, { SidebarProps } from "./components/Sidebar";
import { unitPageLayout } from "./styles";

type UnitPageLayoutProps = {
  Sidebar: SidebarProps;
  Navbar: NavbarProps;
  Content: ContentProps;
};

const UnitPageLayout: FC & UnitPageLayoutProps = ({ children }) => (
  <div css={unitPageLayout}>{children}</div>
);

UnitPageLayout.Sidebar = Sidebar;
UnitPageLayout.Navbar = Navbar;
UnitPageLayout.Content = Content;

export default UnitPageLayout;
