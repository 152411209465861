import { css } from "@emotion/react";

const HEADER_CONTAINER_MAX_WIDTH = 1000;
const CONTENT_CONTAINER_MAX_WIDTH = 1032;

export const headerStyles = ({ courseOverview }) => css`
  width: 100%;
  background-color: ${courseOverview.background};
  position: relative;
  display: flex;
  justify-content: center;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    max-height: 72px;
    height: 72px;
  }

  .header-container {
    max-width: ${HEADER_CONTAINER_MAX_WIDTH}px;
    width: 100%;
    margin: 1rem;
  }
`;

export const contentStyles = css`
  flex: 1;
  display: flex;

  .content-container {
    margin: 0 auto;
    padding: 2rem 0;
    flex: 1;
    display: flex;

    .has-max-width {
      max-width: ${CONTENT_CONTAINER_MAX_WIDTH}px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }
`;
