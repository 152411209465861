import { t } from "@utils/i18n";
import { Column, TableProps } from "@epignosis_llc/gnosis";
import { Pagination as IPagination } from "types/responses/index";
import { QueryFilter } from "types/common";

export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const SEARCH = "";
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const getCategoriesColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.category"),
      classNames: ["user"],
    },
    {
      accessor: "courses_count",
      cell: t("general.courses"),
      classNames: ["courses"],
      sortOrder: "desc",
    },
    {
      accessor: "parent_name",
      cell: t("categories.parentCategory"),
      classNames: ["parent"],
      sortOrder: "desc",
    },
    {
      accessor: "price",
      cell: t("general.price"),
      classNames: ["price"],
      sortOrder: "desc",
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const DISALLOWED_KEYS: string[] = ["e", "E", "+"];
