import { css } from "@emotion/react";

export const percentageLabelContainer = () => css`
  border-radius: 8px;
  margin-inline-start: 1rem;
  padding: 0rem;
  min-width: 70px;

  .inner-container {
    margin: auto;
  }
`;
