import React, { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "@hooks";
import { URLS } from "@constants/urls";
import { useUIStore } from "@stores";
import SignInPopupModal from "@layouts/ExternalLayout/components/SignInPopupModal";
import SignUpPopupModal from "@layouts/ExternalLayout/components/SignUpPopupModal";

const PublicSubmissionsRoute: FC = () => {
  const location = useLocation();
  const { setShowSignInModal, setShowSignUpModal } = useUIStore();
  const { isAuthenticated } = useAuth();

  // on initial render close sign in modal
  useEffect(() => {
    setShowSignInModal(false);
    setShowSignUpModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SignInPopupModal />
      <SignUpPopupModal />

      {!isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to={URLS.login} state={{ from: location }} replace />
      )}
    </>
  );
};

export default PublicSubmissionsRoute;
