import React, { FC } from "react";
import Header, { HeaderProps } from "./components/Header";
import List, { ListProps } from "./components/List";
import ListItem, { ListItemProps } from "./components/ListItem";

type ToCCompoundProps = {
  Header: HeaderProps;
  List: ListProps;
  ListItem: ListItemProps;
};

const ToC: FC & ToCCompoundProps = ({ children }) => <>{children}</>;

ToC.Header = Header;
ToC.List = List;
ToC.ListItem = ListItem;

export default ToC;
