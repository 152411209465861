import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { useTheme } from "@emotion/react";
import { percentageLabelContainer } from "./styles";
import { ResultsQuestion } from "types/entities";

export type PercentageLabelProps = {
  question: ResultsQuestion;
};

const PercentageLabel: FC<PercentageLabelProps> = ({ question }) => {
  const { unitFooter } = useTheme();

  return (
    <>
      <Tag
        css={percentageLabelContainer}
        style={{ backgroundColor: unitFooter.borderColor, color: unitFooter.textColor }}
      >
        <Text fontSize="sm" className="inner-container">
          {question?.weight_percentage?.toFixed(2)}&rlm;%
        </Text>
      </Tag>
    </>
  );
};

export default PercentageLabel;
