import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { likertContainer } from "./styles";
import LikertScale from "@components/Units/Survey/components/LikertScale/LikertScale";
import { LikertScaleQuestion } from "types/entities";
import { LikertScaleAnswer } from "types/entities/Question";

type LikertScaleContainerProps = {
  questionEntry: LikertScaleQuestion;
  userAnswers: LikertScaleAnswer[];
  onAnswersChange: (answers: LikertScaleAnswer[]) => void;
};

const LikertScaleContainer: FC<LikertScaleContainerProps> = ({
  onAnswersChange,
  userAnswers,
  questionEntry,
}) => {
  const hasCustomAnswers = questionEntry?.has_custom_answers;

  return (
    <form css={likertContainer}>
      {questionEntry.children.map((options, index) => {
        return (
          <div key={index} className="likert-wrapper" id={`group-${index}`}>
            <Text
              className="likert-question-title"
              fontSize="md"
              weight="700"
              as="div"
              dangerouslySetInnerHTML={{ __html: options?.text?.html }}
            />

            <LikertScale
              radioId={options.id}
              options={options}
              hasCustomAnswers={hasCustomAnswers}
              userAnswers={userAnswers}
              onAnswersChange={onAnswersChange}
            />
          </div>
        );
      })}
    </form>
  );
};

export default LikertScaleContainer;
