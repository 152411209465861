// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

// Styles
import { publishButtonStyles } from "./styles";

// Utils, stores, hooks
import { generalNotification } from "@utils/helpers";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { HandledError, handlePublishErrors } from "@errors";
import { useUnitStore } from "@stores";
import { useApplyTranslations } from "@hooks";

// Other imports
import { Course, MyUnit, Section } from "types/entities";
import { publishCourse } from "@views/CourseEdit/api";
import { URLS } from "@constants/urls";
import queryKeys from "@constants/queryKeys";

export type PublishButtonProps = {
  course: Course;
  flatUnits: Array<Section | MyUnit>;
};

const canPublish = (course: Course, flatUnits: Array<Section | MyUnit>): boolean => {
  const { status, policies } = course;
  const { can_publish = false } = policies ?? {};

  if (status !== "active") return can_publish;

  const units = flatUnits.filter((section) => !isSectionItem(section)) as MyUnit[];
  const hasUnpublishedUnits = units
    .filter((unit) => unit.is_active)
    .some((unit) => unit.has_unpublished_changes);

  return can_publish && hasUnpublishedUnits;
};

const PublishButton: FC<PublishButtonProps> = ({ course, flatUnits }) => {
  const { t } = useApplyTranslations();
  const { shouldAllowUpdate } = useUnitStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { status, id, policies } = course;
  const { can_publish = false } = policies ?? {};
  const canPublishCourse = canPublish(course, flatUnits);
  const isActive = status === "active";
  const courseId = id.toString();

  const { mutate: publishCourseMutation, isLoading: publishCourseIsLoading } = useMutation(
    [queryKeys.courses.publish],
    publishCourse,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        // invalidate the users/me endpoint to refetch the can_create_course policies, in order to show/disable/hide the "Add course" button
        queryClient.invalidateQueries([queryKeys.userProfile]);

        generalNotification(
          "success",
          t(`courseEdit.${isActive ? "updatedContentSuccessfully" : "publishedSuccessfully"}`),
        );
      },
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "conflict.mandatory_custom_fields_have_not_been_filled") {
            navigate(URLS.courses.getCourseEditLink({ courseId: id.toString() }), {
              state: { openCourseOptionsDrawer: true },
            });
            return;
          }
          navigate(URLS.courses.courses);
        };

        handlePublishErrors(error, handleError);
      },
    },
  );

  const handlePublish = (): void => {
    if (can_publish) {
      publishCourseMutation(courseId);
    }
  };

  if (!canPublishCourse) return <></>;

  return (
    <div css={publishButtonStyles}>
      <Tooltip content={t("courseEdit.updateContentTooltip")} disabled={!isActive}>
        <Button
          variant="solid"
          color="primaryDarker"
          aria-label="Publish course"
          isLoading={publishCourseIsLoading}
          onClick={handlePublish}
          disabled={!shouldAllowUpdate}
        >
          {isActive ? t("courseEdit.publishChanges") : t("courseEdit.publish")}
        </Button>
      </Tooltip>
    </div>
  );
};

export default PublishButton;
