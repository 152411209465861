// Packages or third-party libraries
import React, { FC } from "react";
import { format } from "date-fns";
import { Text } from "@epignosis_llc/gnosis";
import classNames from "classnames";

// Styles
import { iltSessionDateStyles } from "./styles";

// Helpers
import { hasDatePassed, userTimeZonedDate } from "@utils/helpers";

type IltItemProps = {
  startDatetime: string;
  endDatetime: string;
  shouldDisableGrayOutDate: boolean;
};

const dateClassnames = (isPast: boolean, shouldDisableGrayOutDate: boolean): string =>
  classNames("date", { "is-past": isPast && !shouldDisableGrayOutDate });

const IltSessionDate: FC<IltItemProps> = ({
  startDatetime,
  endDatetime,
  shouldDisableGrayOutDate,
  children,
}) => {
  const isPast = hasDatePassed(endDatetime);
  const startDate = userTimeZonedDate(startDatetime);

  return (
    <div css={iltSessionDateStyles}>
      <div className={dateClassnames(isPast, shouldDisableGrayOutDate)}>
        <Text fontSize="4xl" weight="700">
          {format(startDate, "d")}
        </Text>
        <Text fontSize="md" weight="700">
          {format(startDate, "MMM").toUpperCase()}
        </Text>
      </div>

      {children}
    </div>
  );
};

export default IltSessionDate;
