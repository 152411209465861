import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";

// Layouts
import {
  DashboardLayout,
  BasicLayout,
  UnitLayout,
  ExternalLayout,
  CustomHomePageLayout,
  CourseOverviewLayout,
  LayoutWrapper,
} from "@layouts";

// Permission Utils, stores, hooks
import { featureFlags } from "@config";
import { discussionsPermissions } from "@utils/permissions/discussions";
import { profilePermissions } from "@utils/permissions/profile";
import { categoriesPermissions } from "@utils/permissions/categories";
import { reportsPermissions } from "@utils/permissions/reports";
import { gamificationPermissions } from "@utils/permissions/gamification";
import { ecommercePermissions } from "@utils/permissions/ecommerce";
import { coursesPermissions } from "@utils/permissions/courses";
import { messagesPermissions } from "@utils/permissions/messages";
import { catalogPermissions } from "@utils/permissions/catalog";
import { calendarPermissions } from "@utils/permissions/calendar";
import { accountPermissions } from "@utils/permissions/account";
import { groupPermissions } from "@utils/permissions/groups";
import { branchPermissions } from "@utils/permissions/branches";
import { usersPermissions } from "@utils/permissions/users";
import { importExportPermissions } from "@utils/permissions/importExport";
import { automationsPermissions } from "@utils/permissions/automations";
import { notificationsPermissions } from "@utils/permissions/notifications";
import authService from "@utils/services/AuthService";
import { usersTypesPermissions } from "@utils/permissions/userTypes";
import { useConfigurationStore } from "@stores";
import { conferencePermissions } from "@utils/permissions/conferences";

// Constants
import { useAuth } from "@hooks";
import { userRoles } from "../constants";
import { URLS } from "@constants/urls";

// Lazy load views
import {
  PermissionsRoute,
  PrivateRoute,
  ExternalRoute,
  PublicCourseRoute,
  CmsPublicRoute,
  PublicSubmissionsRoute,
} from "@components";
import RootPage from "./RootPage/RootPage";

const SignIn = React.lazy(() => import("@views/SignIn/SignIn"));
const AutoLogin = React.lazy(() => import("@views/AutoLogin/AutoLogin"));
const ActivateUser = React.lazy(() => import("@views/ActivateUser/ActivateUser"));
const Dashboard = React.lazy(() => import("@views/Dashboard/Dashboard"));
const CustomHomePage = React.lazy(() => import("@views/CustomHomePage/CustomHomePage"));
const CustomHomePageUpsell = React.lazy(() => import("@views/CustomHomePage/CustomHomePageUpsell"));
const MyCourse = React.lazy(() => import("@views/Course/MyCourseNew"));
const MyCourseFiles = React.lazy(() => import("@views/MyCourseFiles/MyCourseFiles"));
const AdminCatalogCourse = React.lazy(() => import("@views/Course/Admin/AdminCatalogCourse"));
const Unit = React.lazy(() => import("@views/Unit/UnitNew"));
const SampleUnit = React.lazy(() => import("@views/Unit/SampleUnitNew"));
const UnitResults = React.lazy(() => import("@views/Unit/UnitResults"));
const Question = React.lazy(() => import("@views/Unit/Question"));
const UnitContinue = React.lazy(() => import("@views/Unit/UnitContinue"));
const MyCourses = React.lazy(() => import("@views/MyCourses/MyCourses"));
const Catalog = React.lazy(() => import("@views/Catalog/Catalog"));
const CourseStore = React.lazy(() => import("@views/CourseStore/CourseStore"));
const Calendar = React.lazy(() => import("@views/Calendar/Calendar"));
const SearchResults = React.lazy(() => import("@views/SearchResults/SearchResults"));
const ProfileSettings = React.lazy(() => import("@views/Settings/ProfileSettings"));
const MyFiles = React.lazy(() => import("@views/MyFiles/MyFiles"));
const MyBadges = React.lazy(() => import("@views/MyBadges/MyBadges"));
const MyProgress = React.lazy(() => import("@views/MyProgress/MyProgress"));
const Messages = React.lazy(() => import("@views/Messages/Messages"));
const Referral = React.lazy(() => import("@views/Referral/Referral"));
const NewMessage = React.lazy(() => import("@views/Messages/NewMessage"));
const MessageThread = React.lazy(() => import("@views/Messages/MessageThread"));
const Discussions = React.lazy(() => import("@views/Discussions/Discussions"));
const NewDiscussion = React.lazy(() => import("@views/Discussions/NewDiscussion"));
const EditDiscussion = React.lazy(() => import("@views/Discussions/DiscussionEdit"));
const DiscussionThread = React.lazy(() => import("@views/Discussions/DiscussionThread"));
const CatalogCourse = React.lazy(() => import("@views/Course/CatalogCourseNew"));
const MyGroups = React.lazy(() => import("@views/MyGroups/MyGroups"));
const CourseEdit = React.lazy(() => import("@views/CourseEdit/CourseEdit"));
const NotFound = React.lazy(() => import("@views/Errors/NotFound"));
const SignUp = React.lazy(() => import("@views/SignUp/SignUp"));
const MyPayments = React.lazy(() => import("@views/MyPayments/MyPayments"));
const Courses = React.lazy(() => import("@views/Courses/Courses"));
const UnitEdit = React.lazy(() => import("@views/UnitEdit/UnitEdit"));
const EmailVerification = React.lazy(() => import("@views/EmailVerification/EmailVerification"));
const Branches = React.lazy(() => import("@views/Branches/Branches"));
const Branch = React.lazy(() => import("@views/Branch/Branch"));
const BranchProperties = React.lazy(
  () => import("@views/Branches/components/BranchProperties/BranchProperties"),
);
const BranchCheckSSOConfig = React.lazy(
  () => import("@views/Branches/components/BranchProperties/components/BranchCheckSSOConfig"),
);
const PasswordCreate = React.lazy(() => import("@views/PasswordCreate/PasswordCreate"));
const PasswordReset = React.lazy(() => import("@views/PasswordReset/PasswordReset"));
const Groups = React.lazy(() => import("@views/Groups/Groups"));
const Group = React.lazy(() => import("@views/Group/Group"));
const ChangePassword = React.lazy(() => import("@views/ChangePassword/ChangePassword"));
const Course = React.lazy(() => import("@views/Course/Course"));
const PublicCourseNavigator = React.lazy(() => import("@views/Course/PublicCourseNavigator"));
// const SingleUnitReports = React.lazy(() => import("@views/UnitReports/Unit"));
const Users = React.lazy(() => import("@views/Users/Users"));
const User = React.lazy(() => import("@views/User/User"));
const AccountAndSettings = React.lazy(() => import("@views/AccountAndSettings/AccountAndSettings"));
const CustomizeTheming = React.lazy(() => import("@views/CustomizeTheming/CustomizeTheming"));
const CustomizeBadges = React.lazy(
  () => import("@views/AccountAndSettings/CustomizeBadges/CustomizeBadges"),
);
const CustomDomain = React.lazy(
  () => import("@views/AccountAndSettings/PortalSettings/components/CustomDomain"),
);
const UsersSettings = React.lazy(() => import("@views/User/components/UserSettings/UserSettings"));
const GroupSettings = React.lazy(
  () => import("@views/Groups/components/GroupSettings/GroupSettings"),
);
const Reports = React.lazy(() => import("@views/Reports/Reports"));
const CourseReports = React.lazy(() => import("@views/Reports/CourseReports/CourseReports"));
const UserReports = React.lazy(() => import("@views/Reports/UserReports/UserReports"));
const SingleCourseReports = React.lazy(() => import("@views/Reports/CourseReports/Course/Course"));
const BranchReports = React.lazy(() => import("@views/Reports/BranchReports/BranchReports"));
const GroupReports = React.lazy(() => import("@views/Reports/GroupReports/GroupReports"));
const UnitReports = React.lazy(() => import("@views/Reports/UnitReports/UnitReports"));
const Automations = React.lazy(() => import("@views/Automations/Automations"));
const SingleUserReports = React.lazy(() => import("@views/Reports/UserReports/User/User"));
const SingleBranchReports = React.lazy(() => import("@views/Reports/BranchReports/Branch/Branch"));
const SingleUnitReports = React.lazy(() => import("@views/Reports/UnitReports/Unit/Unit"));
const SingleGroupReports = React.lazy(() => import("@views/Reports/GroupReports/Group/Group"));
const CustomReports = React.lazy(() => import("@views/Reports/CustomReports/CustomReports"));
const CustomReport = React.lazy(
  () => import("@views/Reports/CustomReports/SingleCustomReport/CustomReport"),
);
const CustomUserFields = React.lazy(
  () => import("@views/AccountAndSettings/UsersSettings/components/CustomUserFields"),
);
const Notifications = React.lazy(() => import("@views/Notifications/Notifications"));
const CheckSSOConfig = React.lazy(
  () => import("@views/AccountAndSettings/UsersSettings/components/CheckSSOConfig"),
);
const GradingHub = React.lazy(() => import("@views/Gradebook/GradingHub"));
const CourseStoreCourse = React.lazy(() => import("@views/CourseStore/Course/CourseStoreCourse"));
const CustomCourseFields = React.lazy(
  () => import("@views/AccountAndSettings/CoursesSettings/CustomCourseFields/CustomCourseFields"),
);
const SubscriptionOptions = React.lazy(() => import("@views/Subscription/SubscriptionOptions"));
const Subscription = React.lazy(() => import("@views/Subscription/Subscription"));
const OnboardingWelcome = React.lazy(
  () => import("@views/Onboarding/OnboardingWelcome/OnboardingWelcome"),
);
const OnboardingNotAvailable = React.lazy(
  () => import("@views/Onboarding/OnboardingNotAvailable/OnboardingNotAvailable"),
);
const OnboardingOptions = React.lazy(
  () => import("@views/Onboarding/OnboardingNextSteps/OnboardingNextSteps"),
);
const CustomCertificates = React.lazy(
  () => import("@views/AccountAndSettings/CoursesSettings/Certificates/CustomCertificates"),
);
const InvoicesList = React.lazy(
  () => import("@views/AccountAndSettings/EcommerceSettings/components/InvoicesList/InvoicesList"),
);

const UserSubmissionAssignment = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Assignment/UserSubmission"),
);

const UserSubmissionSurvey = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Survey/UserSubmission"),
);

const UserSubmissionTest = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Test/UserSubmission"),
);

const Conferences = React.lazy(() => import("@views/Conferences/Conferences"));

const { read: discussionsRead, create: discussionsCreate } = discussionsPermissions;
const { read: profileRead } = profilePermissions;
const {
  read: reportsRead,
  courseReports,
  userReports,
  testReports,
  surveyReports,
  assignmentReports,
  iltReports,
  groupReports: userGroupReports,
  branchReports,
  timeline,
  customReports,
} = reportsPermissions;
const { update: ecommerceUpdate } = ecommercePermissions;

const {
  read: coursesRead,
  union_read: coursesUnionRead,
  unit_read: coursesUnitRead,
  unit_update: coursesUnitUpdate,
  files_shared_read: coursesFilesSharedRead,
} = coursesPermissions;
const { read: gamificationRead, accountGamification } = gamificationPermissions;
const { read: messagesRead, create: messagesCreate } = messagesPermissions;
const { read: catalogRead } = catalogPermissions;
const { read: calendarRead } = calendarPermissions;
const {
  read: accountRead,
  update: accountUpdate,
  domain: customDomain,
  subscription: courseStoreSubscription,
  subscription: subscriptionRead,
} = accountPermissions;

const { createImport, createExport } = importExportPermissions;
const { read: usersRead, update: usersUpdate, create: usersCreate } = usersPermissions;
const { read: groupRead, create: groupCreate } = groupPermissions;
const { read: categoriesRead } = categoriesPermissions;
const { read: userTypesRead } = usersTypesPermissions;

const { read: branchRead, create: branchCreate, update: branchUpdate } = branchPermissions;
const { read: automationsRead } = automationsPermissions;
const { read: notificationsRead } = notificationsPermissions;
const { read: conferencesRead } = conferencePermissions;

const notLearnerRoles = ["administrator", "instructor"];

const reportsMainPagePermissions =
  authService.getDefaultRole() === userRoles.ADMINISTRATOR
    ? [
        courseReports,
        userReports,
        testReports,
        surveyReports,
        assignmentReports,
        iltReports,
        userGroupReports,
        branchReports,
        timeline,
        customReports,
      ]
    : [courseReports, timeline];

const AppRoutes: FC = () => {
  const { isAuthenticated } = useAuth();
  const { domainSettings, userProfileData } = useConfigurationStore();
  const canAccessReferral = userProfileData?.policies?.can_access_referral ?? false;
  const {
    features_allowed_in_plan,
    is_sanctioned: isSanctioned,
    reached_branch_limit: reachedBranchLimit,
    cms,
    sso,
  } = domainSettings ?? {};
  const {
    show_onboarding: showOnboarding,
    show_welcome_questions: showWelcomeQuestions,
    is_owner: isPortalOwner,
  } = userProfileData ?? {};
  const canAccessCustomHomePageBuilder = features_allowed_in_plan?.cms;
  const canAccessCustomReports = features_allowed_in_plan?.custom_reports;
  const { published: isCMSPublished } = cms ?? {};
  const { disable_direct_login: isDirectLoginDisabled } = sso ?? {};
  const showCMSPublicRoute = isCMSPublished && !isAuthenticated;
  const shouldAccessPublicCMS = showCMSPublicRoute && !isDirectLoginDisabled;

  return (
    <LayoutWrapper>
      <Routes>
        {/* Public pages */}
        <Route element={<BasicLayout />}>
          <Route
            path={URLS.root}
            element={shouldAccessPublicCMS ? <CustomHomePage isDisplaying={true} /> : <RootPage />}
          />
          {showCMSPublicRoute && (
            <Route element={<CmsPublicRoute />}>
              <Route
                path={URLS.customHomePage.public}
                element={<CustomHomePage isDisplaying={true} />}
              />
            </Route>
          )}

          <Route path={URLS.login} element={<SignIn />} />
          <Route path={URLS.autologin} element={<AutoLogin />} />
          <Route path={URLS.activateUser} element={<ActivateUser />} />
          <Route path={URLS.signup} element={<SignUp />} />
          <Route path={URLS.emailVerification} element={<EmailVerification />} />
          <Route path={URLS.passwordReset} element={<PasswordReset />} />
          <Route path={URLS.passwordCreate} element={<PasswordCreate />} />
          <Route path={URLS.changePassword} element={<ChangePassword />} />
        </Route>
        {/* <Route path={URLS.customHomePage.public} element={<CustomPageHandler />}></Route> */}

        {/* External view routes */}
        <Route element={<ExternalRoute />}>
          <Route element={<ExternalLayout />}>
            <Route path={URLS.externalCatalog.index} element={<Catalog />} />
          </Route>
          <Route element={<UnitLayout />}>
            <Route path={URLS.publicCourse.sampleUnit} element={<SampleUnit isPublic />} />
          </Route>
          <Route element={<CourseOverviewLayout />}>
            <Route path={URLS.externalCatalog.course} element={<CatalogCourse />} />
          </Route>
        </Route>

        {/* Submissions view routes */}

        <Route element={<PublicSubmissionsRoute />}>
          <Route element={<ExternalLayout />}>
            {!isAuthenticated && (
              <>
                <Route
                  path={URLS.reports.userSubmissionAssignment}
                  element={<UserSubmissionAssignment />}
                />
                <Route
                  path={URLS.reports.userSubmissionSurvey}
                  element={<UserSubmissionSurvey />}
                />

                <Route path={URLS.reports.userSubmissionTest} element={<UserSubmissionTest />} />
              </>
            )}
          </Route>
        </Route>

        {/* Public course */}
        <Route path={URLS.publicCourse.publicCourseNavigator} element={<PublicCourseNavigator />} />

        <Route element={<PublicCourseRoute />}>
          <Route element={<CourseOverviewLayout />}>
            <Route path={URLS.publicCourse.index} element={<MyCourse />} />
          </Route>

          <Route element={<UnitLayout />}>
            <Route
              path={URLS.publicCourse.testQuestion}
              element={<Question type="test" isPublic />}
            />
            <Route
              path={URLS.publicCourse.surveyQuestion}
              element={<Question type="test" isPublic />}
            />
            <Route path={URLS.publicCourse.unitResults} element={<UnitResults isPublic />} />
            <Route path={URLS.publicCourse.unit} element={<Unit isPublic />} />
          </Route>
        </Route>

        {/* Private pages (routes) */}
        <Route element={<PrivateRoute />}>
          {/* Dashboard layout */}
          <Route element={<DashboardLayout />}>
            <Route path={URLS.dashboard} element={<Dashboard />} />
            {isAuthenticated && (
              <>
                <Route
                  path={URLS.reports.userSubmissionAssignment}
                  element={<UserSubmissionAssignment />}
                />
                <Route
                  path={URLS.reports.userSubmissionSurvey}
                  element={<UserSubmissionSurvey />}
                />

                <Route path={URLS.reports.userSubmissionTest} element={<UserSubmissionTest />} />
              </>
            )}

            {/* User pages */}
            <Route element={<PermissionsRoute permissions={[profileRead]} />}>
              <Route path={URLS.user.profile} element={<ProfileSettings />} />
              <Route path={URLS.user.files} element={<MyFiles />} />
              <Route path={URLS.user.groups} element={<MyGroups />} />
              <Route path={URLS.user.payments} element={<MyPayments />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[reportsRead]} />}>
              <Route path={URLS.user.progress} element={<MyProgress />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[gamificationRead]} />}>
              <Route path={URLS.user.badges} element={<MyBadges />} />
            </Route>

            {/* Courses pages */}
            <Route element={<PermissionsRoute roles={["learner"]} permissions={[coursesRead]} />}>
              <Route path={URLS.user.courses} element={<MyCourses />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={["learner"]}
                  permissions={[coursesRead, coursesFilesSharedRead]}
                />
              }
            >
              <Route path={URLS.user.courseFiles} element={<MyCourseFiles />} />
            </Route>

            {/* Calendar page */}
            <Route element={<PermissionsRoute permissions={[calendarRead]} />}>
              <Route path={URLS.user.calendar} element={<Calendar />} />
            </Route>

            {/* Catalog pages */}
            <Route element={<PermissionsRoute permissions={[catalogRead]} />}>
              <Route path={URLS.catalog.index} element={<Catalog />} />
            </Route>
            {/* Course Store Pages */}
            <Route
              element={
                <PermissionsRoute
                  roles={["administrator"]}
                  permissions={[coursesRead, courseStoreSubscription]}
                />
              }
            >
              <Route path={URLS.courseStore.index} element={<CourseStore />} />
            </Route>

            {/* Account and settings page */}
            {featureFlags.accountAndSettings && (
              <Route
                element={
                  <PermissionsRoute
                    permissions={[
                      accountRead,
                      accountUpdate,
                      createExport,
                      createImport,
                      categoriesRead,
                      userTypesRead,
                    ]}
                    requireAll={false}
                  />
                }
              >
                <Route
                  path={URLS.accountAndSettings.accountAndSettings}
                  element={<AccountAndSettings />}
                />
                <Route
                  element={<PermissionsRoute permissions={[customDomain]} requireAll={false} />}
                >
                  <Route path={URLS.accountAndSettings.customDomain} element={<CustomDomain />} />
                </Route>
                <Route
                  element={
                    <PermissionsRoute
                      permissions={[accountRead, accountUpdate]}
                      requireAll={true}
                    />
                  }
                >
                  <Route
                    path={URLS.accountAndSettings.customizeTheming}
                    element={<CustomizeTheming />}
                  />
                  <Route
                    path={URLS.accountAndSettings.checkSSOConfig}
                    element={<CheckSSOConfig />}
                  />
                </Route>

                <Route
                  element={
                    <PermissionsRoute
                      permissions={[accountRead, accountUpdate]}
                      requireAll={false}
                    />
                  }
                >
                  <Route
                    path={URLS.accountAndSettings.customUserFields}
                    element={<CustomUserFields />}
                  />
                  <Route
                    path={URLS.accountAndSettings.customCourseFields}
                    element={<CustomCourseFields />}
                  />
                  <Route element={<PermissionsRoute permissions={[accountGamification]} />}>
                    <Route
                      path={URLS.accountAndSettings.customizeBadges}
                      element={<CustomizeBadges />}
                    />
                  </Route>
                  <Route
                    path={URLS.accountAndSettings.certificates}
                    element={<CustomCertificates />}
                  />
                </Route>
              </Route>
            )}

            {featureFlags.showSubscriptionTab && (
              <Route element={<PermissionsRoute permissions={[subscriptionRead]} />}>
                <Route path={URLS.subscription.subscription} element={<Subscription />} />
                <Route
                  path={URLS.subscription.subscriptionOptions}
                  element={<SubscriptionOptions />}
                />
              </Route>
            )}

            {featureFlags.showEcommerce && (
              <Route element={<PermissionsRoute permissions={[accountUpdate, ecommerceUpdate]} />}>
                <Route path={URLS.accountAndSettings.invoiceList} element={<InvoicesList />} />
              </Route>
            )}

            {/* Automations page */}
            {featureFlags.eventsEngine && (
              <Route element={<PermissionsRoute permissions={[automationsRead]} />}>
                <Route path={URLS.automations.automations} element={<Automations />} />
              </Route>
            )}

            {/* Notifications page */}
            {featureFlags.eventsEngine && (
              <Route element={<PermissionsRoute permissions={[notificationsRead]} />}>
                <Route path={URLS.notifications.notifications} element={<Notifications />} />
              </Route>
            )}

            {/* Discussions pages */}
            <Route element={<PermissionsRoute permissions={[discussionsRead]} />}>
              <Route path={URLS.user.singleDiscussion} element={<DiscussionThread />} />
              <Route path={URLS.user.editDiscussion} element={<EditDiscussion />} />
            </Route>
            <Route
              element={<PermissionsRoute permissions={[discussionsRead, discussionsCreate]} />}
            >
              <Route path={URLS.user.newDiscussion} element={<NewDiscussion />} />
            </Route>

            {/* Referral page */}
            <Route element={<PermissionsRoute permissions={[]} canAccess={canAccessReferral} />}>
              <Route path={URLS.referral.referral} element={<Referral />} />
            </Route>

            {/* Messages pages */}
            <Route element={<PermissionsRoute permissions={[messagesRead, messagesCreate]} />}>
              <Route path={URLS.user.newMessage} element={<NewMessage />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[messagesRead]} />}>
              <Route path={URLS.user.inboxMessage} element={<MessageThread type="inbox" />} />
              <Route path={URLS.user.sentMessage} element={<MessageThread type="sent" />} />
            </Route>

            {/* Search pages */}
            <Route path={URLS.search.all} element={<SearchResults />} />
            {!featureFlags.accountAndSettings && (
              <Route element={<PermissionsRoute permissions={[accountRead, accountUpdate]} />}>
                <Route path={URLS.user.customizeTheming} element={<CustomizeTheming />} />
              </Route>
            )}

            {/*CustomHomePage-upsell*/}
            {featureFlags.customHomePage && (
              <Route
                element={
                  <PermissionsRoute
                    permissions={[accountUpdate]}
                    requireAll={true}
                    canAccess={!canAccessCustomHomePageBuilder}
                  />
                }
              >
                <Route
                  path={URLS.customHomePage.customHomePage}
                  element={<CustomHomePageUpsell />}
                />
              </Route>
            )}
          </Route>

          {/* Dashboard layout - noMainContentPadding */}
          <Route element={<DashboardLayout />}>
            {/* Messages page */}
            <Route element={<PermissionsRoute permissions={[messagesRead]} />}>
              <Route path={URLS.user.messages} element={<Messages />} />
            </Route>

            {/* Discussions page */}
            <Route element={<PermissionsRoute permissions={[discussionsRead]} />}>
              <Route path={URLS.user.discussions} element={<Discussions />} />
            </Route>
          </Route>

          {/* Unit pages */}
          <Route
            element={
              <PermissionsRoute roles={["learner"]} permissions={[coursesRead, coursesUnitRead]} />
            }
          >
            {/* Unit layout */}
            <Route element={<UnitLayout />}>
              <Route path={URLS.user.unit} element={<Unit />} />
              <Route path={URLS.catalog.sampleUnit} element={<SampleUnit />} />
              <Route path={URLS.user.unitResults} element={<UnitResults />} />
              <Route path={URLS.user.testQuestion} element={<Question type="test" />} />
              <Route path={URLS.user.surveyQuestion} element={<Question type="survey" />} />
            </Route>

            <Route path={URLS.user.unitContinue} element={<UnitContinue />} />
          </Route>

          <Route element={<PermissionsRoute permissions={[catalogRead]} />}>
            <Route path={URLS.catalog.index} element={<Catalog />} />
          </Route>

          {/* Course overview pages */}
          <Route element={<CourseOverviewLayout />}>
            <Route element={<PermissionsRoute permissions={[catalogRead]} />}>
              <Route path={URLS.catalog.course} element={<CatalogCourse />} />
            </Route>
            <Route
              element={
                <PermissionsRoute
                  roles={["learner"]}
                  permissions={[coursesRead, coursesUnitRead]}
                />
              }
            >
              <Route path={URLS.user.course} element={<MyCourse />} />
            </Route>
          </Route>

          {/****************************/}
          {/* Admin / Instructor pages */}
          {/****************************/}

          <Route element={<DashboardLayout />}>
            {/* Courses pages */}
            {featureFlags.coursesList && (
              <>
                <Route
                  element={
                    <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                  }
                >
                  <Route path={URLS.courses.courses} element={<Courses />} />
                </Route>
              </>
            )}
            {featureFlags.courseUnits && (
              <Route
                element={
                  <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                }
              >
                <Route path={URLS.courses.singleCourse} element={<Course />} />
              </Route>
            )}
            {/* Branches page */}
            {featureFlags.branchesList && (
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={[branchRead]}
                    requireAll={false}
                  />
                }
              >
                <>
                  <Route path={URLS.branches.branches} element={<Branches />} />
                  {featureFlags.branchCreateUpdate && (
                    <Route
                      element={
                        <PermissionsRoute
                          permissions={[branchCreate]}
                          canAccess={!reachedBranchLimit}
                        />
                      }
                    >
                      <Route path={URLS.branches.create} element={<BranchProperties />} />
                    </Route>
                  )}

                  <Route path={URLS.branches.branch} element={<Branch />} />

                  <Route path={URLS.branches.checkSSOConfig} element={<BranchCheckSSOConfig />} />
                </>
              </Route>
            )}
            {/* Conferences page */}
            <Route
              element={<PermissionsRoute roles={["instructor"]} permissions={[conferencesRead]} />}
            >
              <Route path={URLS.conferences.index} element={<Conferences />} />
            </Route>

            {/* Users page */}
            {featureFlags.usersList && (
              <>
                <Route
                  element={
                    <PermissionsRoute
                      roles={notLearnerRoles}
                      permissions={[usersRead]}
                      requireAll={false}
                    />
                  }
                >
                  <Route path={URLS.users.users} element={<Users />} />
                  <Route path={URLS.users.user} element={<User />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[usersRead, usersUpdate]} />}>
                  <Route path={URLS.users.edit} element={<UsersSettings />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[usersCreate]} />}>
                  <Route path={URLS.users.create} element={<UsersSettings />} />
                </Route>
              </>
            )}
            {/* Groups pages */}
            {featureFlags.groups && (
              <>
                <Route
                  element={
                    <PermissionsRoute
                      roles={notLearnerRoles}
                      permissions={[groupRead]}
                      requireAll={false}
                    />
                  }
                >
                  <Route path={URLS.groups.groups} element={<Groups />} />
                  <Route path={URLS.groups.group} element={<Group />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[groupCreate]} />}>
                  <Route path={URLS.groups.create} element={<GroupSettings />} />
                </Route>
              </>
            )}

            {/* Reports pages */}
            {featureFlags.reports && (
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={reportsMainPagePermissions}
                    requireAll={false}
                  />
                }
              >
                <Route path={URLS.reports.reports} element={<Reports />} />
              </Route>
            )}
            {featureFlags.courseReports && (
              <Route
                element={<PermissionsRoute roles={notLearnerRoles} permissions={[courseReports]} />}
              >
                <Route path={URLS.reports.courseReports} element={<CourseReports />} />
                <Route path={URLS.reports.course} element={<SingleCourseReports />} />
              </Route>
            )}

            {featureFlags.userReports && (
              <Route
                element={<PermissionsRoute roles={["administrator"]} permissions={[userReports]} />}
              >
                <Route path={URLS.reports.userReports} element={<UserReports />} />
                <Route path={URLS.reports.user} element={<SingleUserReports />} />
              </Route>
            )}

            {featureFlags.groupReports && (
              <Route
                element={
                  <PermissionsRoute roles={["administrator"]} permissions={[userGroupReports]} />
                }
              >
                <Route path={URLS.reports.groupReports} element={<GroupReports />} />
                <Route path={URLS.reports.group} element={<SingleGroupReports />} />
              </Route>
            )}

            {featureFlags.branchReports && (
              <Route
                element={
                  <PermissionsRoute roles={["administrator"]} permissions={[branchReports]} />
                }
              >
                <Route path={URLS.reports.branchReports} element={<BranchReports />} />
                <Route path={URLS.reports.branch} element={<SingleBranchReports />} />
              </Route>
            )}

            <Route
              element={
                <PermissionsRoute roles={["instructor"]} permissions={[coursesUnitUpdate]} />
              }
            >
              <Route path={URLS.gradingHub.gradingHub} element={<GradingHub />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[testReports, surveyReports, assignmentReports, iltReports]}
                  requireAll={false}
                />
              }
            >
              <Route path={URLS.reports.learningActivities} element={<UnitReports />} />
            </Route>
            {featureFlags.customReports && (
              <Route
                element={
                  <PermissionsRoute roles={["administrator"]} permissions={[customReports]} />
                }
              >
                <Route path={URLS.reports.customReports} element={<CustomReports />} />
                <Route
                  element={
                    <PermissionsRoute
                      roles={["administrator"]}
                      permissions={[customReports]}
                      canAccess={canAccessCustomReports}
                    />
                  }
                >
                  <Route path={URLS.reports.customReport} element={<CustomReport />} />
                </Route>
              </Route>
            )}

            <Route
              element={<PermissionsRoute roles={notLearnerRoles} permissions={[testReports]} />}
            >
              <Route
                path={URLS.reports.units.testUnit}
                element={<SingleUnitReports unitType="test" />}
              />
            </Route>

            <Route
              element={<PermissionsRoute roles={notLearnerRoles} permissions={[testReports]} />}
            >
              <Route
                path={URLS.reports.units.scormUnit}
                element={<SingleUnitReports unitType="scorm" />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute roles={notLearnerRoles} permissions={[assignmentReports]} />
              }
            >
              <Route
                path={URLS.reports.units.assignmentUnit}
                element={<SingleUnitReports unitType="assignment" />}
              />
            </Route>

            <Route
              element={<PermissionsRoute roles={notLearnerRoles} permissions={[surveyReports]} />}
            >
              <Route
                path={URLS.reports.units.surveyUnit}
                element={<SingleUnitReports unitType="survey" />}
              />
            </Route>

            <Route
              element={<PermissionsRoute roles={notLearnerRoles} permissions={[iltReports]} />}
            >
              <Route
                path={URLS.reports.units.iltUnit}
                element={<SingleUnitReports unitType="ilt" />}
              />
            </Route>
          </Route>

          {/* Course overview pages */}
          <Route element={<CourseOverviewLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={["administrator"]}
                  permissions={[coursesRead, courseStoreSubscription]}
                />
              }
            >
              <Route path={URLS.courseStore.course} element={<CourseStoreCourse />} />
            </Route>
            <Route
              element={<PermissionsRoute roles={notLearnerRoles} permissions={[coursesRead]} />}
            >
              <Route path={URLS.catalog.adminCourse} element={<AdminCatalogCourse />} />
            </Route>
          </Route>

          {/* Custom Home Page */}
          {featureFlags.customHomePage && (
            <>
              <Route
                element={
                  <PermissionsRoute
                    permissions={[accountUpdate, branchUpdate]}
                    requireAll={false}
                  />
                }
              >
                <Route element={<CustomHomePageLayout />}>
                  {/* Builder/Edit mode routes */}
                  <Route path={URLS.customHomePage.builder} element={<CustomHomePage />} />
                  {/* Preview Routes  */}
                  <Route
                    path={URLS.customHomePage.preview}
                    element={<CustomHomePage isPreview={true} />}
                  />
                  <Route
                    path={URLS.customHomePage.previewSlug}
                    element={<CustomHomePage isPreview={true} />}
                  />
                </Route>
              </Route>
              {/* Other Private Routes  */}
              <Route path={URLS.customHomePage.privateSlug} element={<DashboardLayout />}>
                <Route
                  path={URLS.customHomePage.privateSlug}
                  element={<CustomHomePage isDisplaying={true} />}
                />
              </Route>
            </>
          )}

          {/* Manage course pages */}
          <Route element={<UnitLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[coursesRead, coursesUnitRead]}
                />
              }
            >
              <Route element={<UnitLayout />}>
                <Route path={URLS.user.unitPreview} element={<Unit isPreview />} />
              </Route>
            </Route>

            <Route
              element={
                <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
              }
            >
              <Route path={URLS.courses.edit} element={<CourseEdit />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[coursesUnionRead, coursesUnitRead]}
                />
              }
            >
              <Route path={URLS.units.edit} element={<UnitEdit />} />
            </Route>
          </Route>

          {/* 404 page */}
          <Route path="*" element={<BasicLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Onboarding page */}
          <Route element={<BasicLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={["administrator"]}
                  permissions={[accountUpdate]}
                  canAccess={isPortalOwner && showWelcomeQuestions}
                />
              }
            >
              <Route path={URLS.onboardingWelcome} element={<OnboardingWelcome />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={["administrator"]}
                  permissions={[accountUpdate]}
                  canAccess={isPortalOwner && showOnboarding}
                />
              }
            >
              <Route path={URLS.onboardingOptions} element={<OnboardingOptions />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={["administrator"]}
                  permissions={[accountUpdate]}
                  canAccess={isPortalOwner && isSanctioned}
                />
              }
            >
              <Route path={URLS.onboardingNotAvailable} element={<OnboardingNotAvailable />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </LayoutWrapper>
  );
};
export default AppRoutes;
