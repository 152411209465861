import React, { FC } from "react";
import { Dropdown, DropdownItem, Text } from "@epignosis_llc/gnosis";
import { MyGroupsSVG } from "@epignosis_llc/gnosis/icons";
import { useNavigate } from "react-router-dom";
import { bundlesStyles } from "./styles";
import { Bundle } from "types/entities";
import { URLS } from "@constants/urls";
import { useApplyTranslations, useIsExternalView } from "@hooks";

export type BundlesProps = {
  bundles: Bundle[];
};

const Bundles: FC<BundlesProps> = ({ bundles }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const isExternalView = useIsExternalView();

  const dropdownListData: DropdownItem[] = bundles.map(({ name, type, id }) => {
    return {
      label: name,
      value: type,
      id: id.toString(),
      icon: <MyGroupsSVG height={32} />,
    };
  });

  const handleBundleSelect = (item: DropdownItem): void => {
    const bundleFound = bundles.find(
      ({ id, type }) => id.toString() === item.id && type === item.value,
    );

    if (bundleFound) {
      const catalogLink = isExternalView ? URLS.externalCatalog.index : URLS.catalog.index;
      navigate(catalogLink, { state: { activeBundle: bundleFound } });
    }
  };

  return (
    <div css={bundlesStyles}>
      <Dropdown list={dropdownListData} onListItemSelect={handleBundleSelect}>
        <Text
          fontSize="sm"
          dangerouslySetInnerHTML={{
            __html: t("myCourses.bundlesText", { count: bundles.length }),
          }}
        />
      </Dropdown>
    </div>
  );
};

export default Bundles;
