import React, { FC, ReactNode, useRef } from "react";
import classNames from "classnames";
import { Tooltip } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { listItemContainer } from "./styles";
import { Link } from "@components/ReusableComponents";
import { useIsOverflow } from "@hooks";

export type ListItemProps = FC<{
  text: string;
  icon?: JSX.Element;
  /**
   * If `active === true`, the font weight will be bold to highlight
   * that the list item is active
   */
  active?: boolean;
  /**
   * When `disabled` is `true`, styles will be applied to
   * imply that the list item is disabled and any links will
   * not work
   */
  disabled?: boolean;
  href?: string;
  maxLines?: number;
  children?: ReactNode;
  onClick?: () => void;
}>;

const contentClassNames = (disabled: boolean, active: boolean): string =>
  classNames({
    disabled: disabled,
    "list-item-container": true,
    "is-active": active,
  });

const ListItem: ListItemProps = ({
  text,
  icon,
  active = false,
  disabled = false,
  href,
  maxLines = 2,
  children,
  onClick,
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  const content = (
    <Tooltip content={text} disabled={!overflowActive}>
      <span className={contentClassNames(disabled, active)}>
        {icon ? <div>{icon}</div> : null}

        <span className="list-item" ref={ref}>
          {text}
        </span>
        {children}
      </span>
    </Tooltip>
  );

  return (
    <dd css={(theme): SerializedStyles => listItemContainer(theme, { maxLines })}>
      {!disabled && href ? (
        <Link to={href} onClick={onClick}>
          {content}
        </Link>
      ) : (
        content
      )}
    </dd>
  );
};

export default ListItem;
