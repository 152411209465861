import { css } from "@emotion/react";
import { GlobalStyleVariables } from "@styles";
const { unitsMaxWidth } = GlobalStyleVariables;

export const iFrameContainer = (width, height, hasUrlAndContent, hasUrlAndSecondaryContent) => css`
  height: 100%;

  .iframe-container {
    height: 100%;
    width: 100%;
    max-height: ${height ? `${height}px` : "100%"};
    max-width: ${width ? `${width}px` : unitsMaxWidth};
    margin: auto;
    border: 1px solid #ececec;
    min-height: ${height ? `${height}px` : "554px"};
  }

  .content {
    padding-bottom: ${hasUrlAndContent ? "2rem" : "0"};
    word-break: break-word;
  }

  .secondary-content {
    padding-top: ${hasUrlAndSecondaryContent ? "2rem" : "0"};
    word-break: break-word;
  }
`;

export const popupContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  .pop-up-content {
    max-width: 460px;
    text-align: center;

    .pop-up-content-text {
      margin-bottom: 0.5rem;
    }
  }

  .question {
    width: 100%;
  }
`;
