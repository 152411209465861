import { css } from "@emotion/react";

export const unitPageLayout = css`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const unitPageLayoutContent = () => css`
  width: 100%;
  height: 100%;
`;
