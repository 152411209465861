import { MassActionParam } from "@components/ReusableComponents";
import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import {
  CountMassActionResponse,
  GradingHubPendingCountRes,
  MassActionResultResponse,
} from "types/responses";
import { GradeFormData } from "types/entities/Assignments";

export const getGradingHubCount = async (): Promise<GradingHubPendingCountRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.gradingHub.count}`);

  return res.data;
};

export const submissionsMassActionsCount = async (
  type: string,
  data: MassActionParam,
): Promise<CountMassActionResponse> => {
  let endpoint = "";
  const body = { ...data };
  if (!body.status) delete body.status;

  switch (type) {
    case "grade":
      endpoint = ENDPOINTS.gradingHub.assignments.massActions.grade.count;
      break;
    case "reset":
      endpoint = ENDPOINTS.gradingHub.assignments.massActions.reset.count;
      break;
    default:
      break;
  }

  const res = await HttpClient.post(endpoint, body);
  return res.data;
};

export const submissionsMassActions = async (
  type: string,
  data: MassActionParam,
): Promise<MassActionResultResponse> => {
  let endpoint = "";
  const body = { ...data };

  if (!body.status) delete body.status;

  switch (type) {
    case "grade":
      endpoint = ENDPOINTS.gradingHub.assignments.massActions.grade.grade;
      break;
    case "reset":
      endpoint = ENDPOINTS.gradingHub.assignments.massActions.reset.reset;
      break;
    default:
      break;
  }

  const res = await HttpClient.post(endpoint, body);
  return res.data;
};

export const resetSubmission = async (unitId: string, userId: string): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.gradingHub.reset(unitId, userId));
};

export const gradeSubmission = async (
  unitId: string,
  userId: string,
  data: GradeFormData,
): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.gradingHub.grade(unitId, userId), { ...data });
};
