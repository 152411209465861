// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Grid, Modal, Select, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";

// Styles
import { modalFooter } from "./styles";

// Components
import { Skeletons } from "@components";

// Utils, hooks
import {
  buildPaginatedSearchQuery,
  coursesLightToOptions,
  generalNotification,
  mapTableToSelectSorting,
  unitsToOptions,
} from "@utils/helpers";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { useApplyTranslations, usePaginatedStateReducer } from "@hooks";
import { useCloneCourseUnit } from "@views/CourseEdit/hooks";
import { handleUnitErrors } from "@errors";
import { cloneUnitValidationSchema } from "@views/CourseEdit/validations";
import { flatsUnitsReducer } from "@views/CourseEdit/reducers";

// Other imports
import { getCoursesLight, getMyCourseUnits } from "@api/courses";
import { SelectOption } from "types/common";
import { MyUnit } from "types/entities";
import queryKeys from "@constants/queryKeys";
import { cloneUnitCoursesDefaultState } from "@views/CourseEdit/constants";

type CloneModalProps = {
  isOpen: boolean;
  targetCourseId: string;
  onClose: () => void;
};

type cloneCourseUnitData = {
  unitId: string | null;
  targetCourseId: string;
};

const CloneModal: FC<CloneModalProps> = ({ isOpen, targetCourseId, onClose }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const [courseUnits, setCourseUnits] = useState<MyUnit[]>([]);
  const [coursesState] = usePaginatedStateReducer(cloneUnitCoursesDefaultState);
  const { pagination, sorting: tableSorting, filters } = coursesState;
  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = `${buildPaginatedSearchQuery({
    pagination,
    sorting,
    filters,
  })}&course_id=${targetCourseId}`;

  const {
    control,
    watch,
    formState: { isValid },
    setValue,
    trigger,
    handleSubmit,
    reset,
  } = useForm<cloneCourseUnitData>({
    mode: "onChange",
    resolver: yupResolver(cloneUnitValidationSchema),
  });

  const selectedCourseId = watch("targetCourseId");

  const {
    data: courses = [],
    status: coursesStatus,
    error: coursesError,
  } = useQuery([queryKeys.courses.coursesLight, searchQuery], () => getCoursesLight(searchQuery), {
    refetchOnWindowFocus: false,
    select: (res) => res._data,
  });

  const { mutate: getCourseUnitsMutation, isLoading: getCourseUnitsLoading } = useMutation(
    [queryKeys.units, selectedCourseId],
    (courseId: string) => getMyCourseUnits(courseId),
    {
      onSuccess: (res) => {
        // keep only units that can be cloned
        const units = res._data
          .reduce(flatsUnitsReducer, [])
          .filter(
            (section) => !isSectionItem(section) && Boolean(section.policies?.can_clone),
          ) as MyUnit[];

        setCourseUnits(units);
      },
    },
  );

  const { mutate: cloneUnitMutation, isLoading: cloneUnitLoading } = useCloneCourseUnit({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, targetCourseId]);
        generalNotification("success", t("courseEdit.unitClonedSuccessfully"));
        reset();
      },
      onError: (err): void => {
        const error = err as AxiosError;
        handleUnitErrors(error);
      },
    },
  });

  const coursesOptions = coursesLightToOptions(courses);
  const unitOptions = unitsToOptions(courseUnits);

  const handleCourseChange = (courseId: string): void => {
    setValue("unitId", null);
    trigger("unitId");
    getCourseUnitsMutation(courseId);
  };

  const handleClone = ({ unitId }: cloneCourseUnitData): void => {
    if (unitId && targetCourseId) {
      cloneUnitMutation({ unitId, targetCourseId });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>{t("units.cloneFromCourse")}</Modal.Header>
      <Modal.Body>
        <Skeletons.Loader status={coursesStatus} error={coursesError}>
          <Grid templateColumns={1} gap={1.5}>
            <Grid.Item colSpan={1}>
              <Text fontSize="xs">{t("units.cloneFromCourseText")}</Text>
            </Grid.Item>
            <Grid.Item colSpan={1}>
              <Controller
                name="targetCourseId"
                control={control}
                render={({ field: { value, onChange } }): JSX.Element => {
                  const selectedValue = value
                    ? coursesOptions.find((option) => option.value === value)
                    : null;

                  return (
                    <Select
                      id="clone-course-select"
                      label={t("general.course")}
                      placeholder={t("general.selectACourse")}
                      options={coursesOptions}
                      value={selectedValue}
                      onChange={(newValue): void => {
                        const { value: courseId } = newValue as SelectOption;
                        onChange(courseId);
                        handleCourseChange(courseId);
                      }}
                    />
                  );
                }}
              />
            </Grid.Item>
            <Grid.Item colSpan={1}>
              <Controller
                name="unitId"
                control={control}
                render={({ field: { value, onChange } }): JSX.Element => {
                  const selectedValue = value
                    ? unitOptions.find((option) => option.value === value)
                    : null;

                  return (
                    <Select
                      id="clone-course-unit-select"
                      label={t("general.unit")}
                      placeholder={t("general.selectUnits", { count: 1 })}
                      options={unitOptions}
                      value={selectedValue}
                      isDisabled={!selectedCourseId || getCourseUnitsLoading}
                      onChange={(newValue): void => {
                        const { value: unitId } = newValue as SelectOption;
                        onChange(unitId);
                      }}
                    />
                  );
                }}
              />
            </Grid.Item>
          </Grid>
        </Skeletons.Loader>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button
            color="primary"
            disabled={!isValid}
            isLoading={cloneUnitLoading}
            onClick={handleSubmit(handleClone)}
          >
            {t("general.clone")}
          </Button>
          <Button color="secondary" onClick={onClose}>
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CloneModal;
