import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const timeLimitBarContainer = ({ timeLimitBar }) => css`
  // same padding as unit-wrapper
  padding: 1rem 2rem;

  ${mq["md"]} {
    padding: 1rem 3rem;
  }

  .time-limit-bar-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    position: relative;
  }

  .barWrapper {
    background-color: ${timeLimitBar.barBackground};
    border-radius: 12px;
  }

  .timestamp {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .bar {
    height: 0.5rem;
    background-color: ${timeLimitBar.background};
  }
`;
