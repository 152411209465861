import { coursesMassActionWordings } from "@views/Courses/constants";
import { handleCourseErrors } from "@errors";
import { branchesMassActionWordings } from "@views/Branches/constants";
import {
  handleBranchesMassActionsErrors,
  handleCustomReportErrors,
  handleGenericMassActionsErrors,
  handleGroupUsersMassActionsErrors,
} from "@errors/errors";
import { groupsMassActionWordings } from "@views/Groups/constants";
import { enrollUsersToCourseWordings } from "@views/Course/CourseUsers/components/constants";
import { t } from "@utils/i18n";
import { DropdownItem } from "@epignosis_llc/gnosis";
import { courseUsersWordings } from "@views/Course/CourseUsers/constants";
import { usersMassActionsWordings } from "@views/Users/constants";
import { userUnitsMassActionsWordings } from "@views/Reports/UnitReports/Unit/constants";
import { groupUsersMassActionsWordings } from "@views/Group/components/GroupUsers/constants";
import { submissionsMassActionsWordings } from "@components/Submissions/constants";
import { handleAssignmentErrors } from "@errors/assignmentErrors";
import { courseStoreMassActionsWordings } from "@views/CourseStore/constants";
import { customReportMassActionsWordings } from "@views/Reports/CustomReports/components/constants";

export const tableItems = {
  courses: {
    wordings: coursesMassActionWordings,
    errorHandler: handleCourseErrors,
  },
  branches: {
    wordings: branchesMassActionWordings,
    errorHandler: handleBranchesMassActionsErrors,
  },
  groups: {
    wordings: groupsMassActionWordings,
    errorHandler: handleGenericMassActionsErrors,
  },
  courseUsers: {
    wordings: courseUsersWordings,
    errorHandler: handleCourseErrors,
  },
  enrollCourseUsers: {
    wordings: enrollUsersToCourseWordings,
    errorHandler: handleCourseErrors,
  },
  users: {
    wordings: usersMassActionsWordings,
    errorHandler: handleGenericMassActionsErrors,
  },
  unitUsers: {
    wordings: userUnitsMassActionsWordings,
    errorHandler: handleGenericMassActionsErrors,
  },
  groupUsers: {
    wordings: groupUsersMassActionsWordings,
    errorHandler: handleGroupUsersMassActionsErrors,
  },
  assignments: {
    wordings: submissionsMassActionsWordings,
    errorHandler: handleAssignmentErrors,
  },
  courseStore: {
    wordings: courseStoreMassActionsWordings,
    errorHandler: handleCourseErrors,
  },
  customReport: {
    wordings: customReportMassActionsWordings,
    errorHandler: handleCustomReportErrors,
  },
};

export const resetDropdownOptions: DropdownItem[] = [
  {
    label: t("users.courses.resetAndRemoveCertificate"),
    value: "reset",
  },
];

export const coursesSearchQueryObject = {
  pagination: { number: 1, size: 10000 },
  filters: [{ key: "[status]", value: "active" }],
  sorting: ["name"],
};

// Here add the types that trigger count on first modal open
export const triggerCountOnMountTypes = [
  "activate",
  "deactivate",
  "delete",
  "show_in_catalog",
  "hide_from_catalog",
  "enroll",
  "unenroll",
  "reset",
  "update_certificates",
  "complete_units",
  "reset_units",
  "add_users_to_group_courses",
  "remove_users_from_group_courses",
  "course_store_mass_acquire",
  "custom_reports_delete_users",
];
