import React, { FC, useMemo, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/utils";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useClickAway, useResponsive } from "ahooks";

import { Badge, Sidebar, Text, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, CloseSVG, ArrowRightChevronSVG } from "@epignosis_llc/gnosis/icons";
import { optionMenuItem } from "./styles";

import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { useConfigurationStore } from "@stores";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import { featureFlags } from "@config";
import { useApplyTranslations } from "@hooks";

import userRoles from "@constants/userRoles";
import { MenuEntry } from "../types";
import { secondaryURLS } from "@constants/urls";

type MenuWithSubmenusProps = {
  title: string;
  drawerKey: string | null;
  url: string | null;
  icon: JSX.Element | null;
  submenu?: MenuEntry[];
  withBadge?: boolean;
  badgeNumber?: number;
  isCollapsed?: boolean;
  handleClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLLIElement, MouseEvent>,
    drawerKey: MainDrawerContent,
  ) => void;
};

const subMenuClassNames = (
  position: boolean,
  isMedium: boolean,
  activeMenu: string | null,
  drawerKey: string | null,
  isCollapsed: boolean | undefined,
  isRoleActive: boolean,
): string =>
  classNames("sub-menu-container", {
    upwards: position,
    mobile: !isMedium,
    "is-role-submenu": isCollapsed && drawerKey === "role",
    "force-show": isRoleActive,
    "is-active": activeMenu === drawerKey,
  });

const navItemClassNames = (isActiveTab: boolean, drawerKey: string, clicked: boolean): string =>
  classNames(drawerKey, "nav-item", {
    active: isActiveTab,
    "force-hide": clicked,
  });

const MenuWithSubmenus: FC<MenuWithSubmenusProps> = ({
  title,
  drawerKey,
  submenu,
  icon,
  badgeNumber,
  withBadge,
  url,
  isCollapsed,
  handleClick,
}) => {
  const { t, i18n } = useApplyTranslations();
  const { md: isMedium } = useResponsive();
  const { domainSettings } = useConfigurationStore();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const topLevelItem = useRef<HTMLLIElement | null>(null);
  const [shouldExpandUpwards, setShouldExpandUpwards] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isRoleActive, setIsRoleActive] = useState(false);

  const {
    canAccessCourseReports,
    canAccessUserReports,
    canAccessBranchReports,
    canAccessGroupReports,
    canAccessTimeline,
    canAccessReports,
    canAccessUnitReports,
    canAccessCustomReports,
  } = permissions.reportsPermissions;
  const { canAccessAutomations } = permissions.automationsPermissions;
  const { canAccessNotifications } = permissions.notificationsPermissions;
  const { canAccessAccount } = permissions.accountPermissions;
  const { canUpdateGamification } = permissions.gamificationPermissions;
  const { canUpdateEcommerce } = permissions.ecommercePermissions;
  const { canExport, canImport } = permissions.importExportPermissions;
  const { canAccessCategories } = permissions.categoriesPermissions;
  const { canAccessUserTypes } = permissions.userTypesPermissions;

  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;
  const isInstructor = userRole === userRoles.INSTRUCTOR;

  const allowCourseReportsView = canAccessCourseReports() && (isAdmin || isInstructor);
  const allowUserReportsView = canAccessUserReports() && isAdmin;
  const allowGroupReportsView = canAccessGroupReports() && isAdmin;
  const allowAccessAccount = canAccessAccount() && isAdmin;
  const allowTimelineReportsView = canAccessTimeline();
  const allowImportExportView = canExport() || canImport();
  const allowGamificationView = canAccessAccount() && canUpdateGamification();
  const allowEcommerceView =
    canAccessAccount() && canUpdateEcommerce() && featureFlags.showEcommerce;
  const allowTrainingMatrixReportsView =
    userRole === userRoles.ADMINISTRATOR ? canAccessReports() : canAccessCourseReports();
  const allowLearningActivitiesView = isAdmin && canAccessUnitReports();
  const allowCustomReportsView = canAccessCustomReports() && isAdmin;
  const allowAutomationsView = canAccessAutomations() && isAdmin;
  const allowNotificationsView = canAccessNotifications() && isAdmin;
  const defaultOption = authService.getDefaultRole();
  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const isMainPortal = Boolean(domainSettings?.main_portal);
  const allowBranchReportsView = canAccessBranchReports() && isAdmin && isMainPortal;
  const allowCatagoriesView = canAccessCategories() && isAdmin;
  const canAccessUserTypesView = canAccessUserTypes() && isAdmin;

  const badgePosition = {
    top: badgeNumber?.toString().length === 3 ? "-10px" : "-5px",
    right: badgeNumber?.toString().length === 3 ? "-12px" : "-2px",
  };

  const itemIcon =
    withBadge && Boolean(badgeNumber) ? (
      <Badge color="inherit" badgeContent={badgeNumber?.toString()} offset={badgePosition}>
        {icon}
      </Badge>
    ) : (
      icon
    );

  useClickAway(() => {
    if (isRoleActive) setIsRoleActive(false);
  }, topLevelItem);

  // filter submenu items based on the permissions
  const submenuEntries = useMemo(() => {
    return typeof submenu !== "undefined"
      ? submenu.filter((menuEntry) => {
          // Reports Submenu permissions
          if (menuEntry.drawerKey === "courseReports")
            return allowCourseReportsView && featureFlags.courseReports;
          if (menuEntry.drawerKey === "userReports")
            return allowUserReportsView && featureFlags.userReports;
          if (menuEntry.drawerKey === "branchReports")
            return allowBranchReportsView && featureFlags.branchReports;
          if (menuEntry.drawerKey === "groupReports")
            return allowGroupReportsView && featureFlags.groupReports;
          if (menuEntry.drawerKey === "timeline") return allowTimelineReportsView;
          if (menuEntry.drawerKey === "trainingMatrix") return allowTrainingMatrixReportsView;
          if (menuEntry.drawerKey === "learningActivities") return allowLearningActivitiesView;
          if (menuEntry.drawerKey === "customReports")
            return featureFlags.customReports && allowCustomReportsView;
          // Accounts & Settings Submenu permissions
          if (menuEntry.drawerKey === "portalSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowAccessAccount;
          if (menuEntry.drawerKey === "usersSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowAccessAccount;
          if (menuEntry.drawerKey === "userTypeSettings")
            return featureFlags.accountsAndSettingsSubmenus && canAccessUserTypesView;
          if (menuEntry.drawerKey === "coursesSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowAccessAccount;
          if (menuEntry.drawerKey === "categoriesSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowCatagoriesView;
          if (menuEntry.drawerKey === "gamificationSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowGamificationView;
          if (menuEntry.drawerKey === "ecommerceSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowEcommerceView;
          if (menuEntry.drawerKey === "integrationSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowAccessAccount;
          if (menuEntry.drawerKey === "securitySettings")
            return featureFlags.accountsAndSettingsSubmenus && allowAccessAccount;
          if (menuEntry.drawerKey === "importExportSettings")
            return featureFlags.accountsAndSettingsSubmenus && allowImportExportView;
          if (menuEntry.drawerKey === "billingAndSubscriptionSettings")
            return featureFlags.accountsAndSettingsSubmenus;
          return true;
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowCourseReportsView,
    allowUserReportsView,
    allowBranchReportsView,
    allowGroupReportsView,
    allowNotificationsView,
    allowAutomationsView,
    allowAccessAccount,
    allowGamificationView,
    allowEcommerceView,
    allowImportExportView,
    allowCatagoriesView,
    allowTimelineReportsView,
  ]);

  const hasSubmenu = submenuEntries && submenuEntries.length > 0;

  // ACTIVE PAGE
  const { activeTab } = useUIStore();
  const location = useLocation();
  const pathname = location.pathname;
  const isSecondaryOption =
    secondaryURLS.find((url) => pathname.includes(url)) &&
    pathname.includes(title.toLocaleLowerCase());
  // If the active Page is "customPage" then we should highlight the custom page menu item
  const isCustomHomePage = activeTab === drawerKey;

  // HOTFIX. We should change this implementation.
  const submenuUrls = hasSubmenu ? submenu?.map((item) => item.url) : null;

  const isActiveTab =
    activeTab === url ||
    Boolean(isSecondaryOption) ||
    activeTab === submenuUrls?.find((url) => url === activeTab) ||
    isCustomHomePage;

  const calculatePosition = (): void => {
    if (dropdownRef.current === null) return;

    const rect = dropdownRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (windowHeight <= rect.bottom) setShouldExpandUpwards(true);
  };

  const handleMenuItemMouseOver = (e: React.MouseEvent): void => {
    setIsClicked(false);
    // We don't need to calculate the position on small screens
    if (!isMedium) return;
    e.stopPropagation();
    calculatePosition();
  };

  const handleMenuItemClick = (e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>): void => {
    e.stopPropagation();
    handleClick && handleClick(e, drawerKey as MainDrawerContent);
    isMedium && setIsClicked(true); // When the submenu item is clicked then close the submenu, from md screens and above
  };

  return (
    <Tooltip content={t(title)} disabled={!isCollapsed || hasSubmenu} placement="right">
      <li
        css={(theme): SerializedStyles =>
          optionMenuItem(theme, { isCollapsed, dir, defaultOption })
        }
        className={navItemClassNames(isActiveTab, drawerKey as string, isClicked)}
        id={drawerKey || ""}
        key={title}
        ref={topLevelItem}
        onMouseOver={handleMenuItemMouseOver}
        onMouseLeave={(): void => setShouldExpandUpwards(false)}
        onClick={handleMenuItemClick}
        data-testid={`${drawerKey}-menu-item`}
      >
        <div className="main-nav-item">
          <Link key={drawerKey} to={url || "#"} className="nav-link" onClick={handleMenuItemClick}>
            <Sidebar.Item
              fontSize={"sm"}
              icon={itemIcon}
              label={t(title)}
              isExpanded={!isCollapsed}
            />
          </Link>

          {!isCollapsed && hasSubmenu && (
            <Link
              to={"#"}
              className="nav-arrow-link arrow-right"
              onClick={(e): void => {
                e.stopPropagation();
                setActiveMenu(drawerKey);
              }}
            >
              {isRTL ? <ArrowLeftSVG height={32} /> : <ArrowRightChevronSVG height={32} />}
            </Link>
          )}
        </div>

        {hasSubmenu && (
          <div
            ref={dropdownRef}
            className={subMenuClassNames(
              shouldExpandUpwards,
              isMedium,
              activeMenu,
              drawerKey,
              isCollapsed,
              isRoleActive,
            )}
          >
            <div className="sub-menu">
              {/* MOBILE SUB-MENU HEADER  */}
              {!isMedium && (
                <div className="sub-menu-mobile-header">
                  <Text fontSize="md" as="span" weight="700">
                    {t(`general.${drawerKey}`)}
                  </Text>
                  <button
                    className="close-menu"
                    onClick={(e): void => {
                      e.stopPropagation();
                      setActiveMenu(null);
                    }}
                  >
                    <CloseSVG height={23} />
                  </button>
                </div>
              )}

              {/* SUB-MENUS */}
              {submenuEntries.map(({ title, type, url, icon, drawerKey }) => {
                const subMenuUrl = drawerKey !== "customPage" ? `${url}?tab=${drawerKey}` : url;
                const isNewTab = type === "url";
                // Custom home pages doesn't have translatable titles.
                const linkTitle = drawerKey === "customPage" ? title : t(title);

                return (
                  <Link
                    key={title}
                    to={subMenuUrl || "#"}
                    target={isNewTab ? "_blank" : "_self"}
                    className="sub-nav-link"
                    onClick={(e): void => {
                      handleClick && handleClick(e, drawerKey as MainDrawerContent);
                    }}
                    data-testid={`submenu-${drawerKey?.toLowerCase()}`}
                  >
                    <Sidebar.Item fontSize={"sm"} icon={icon} label={linkTitle} isExpanded={true} />
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </li>
    </Tooltip>
  );
};

export default MenuWithSubmenus;
