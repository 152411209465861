import React, { FC, ReactNode } from "react";
import { questionContainer } from "./styles";
import QuestionsContainer from "./components/QuestionsContainer";
import { Question, QuestionAnswers } from "types/entities";
import QuestionTitle from "./components/QuestionTitle";

export type TestQuestionsContainerProps = {
  questionEntry: Question;
  userAnswers: QuestionAnswers;
  children?: ReactNode;
  onAnswersChange: (answers: QuestionAnswers) => void;
};

const QuestionsTypesContainer: FC<TestQuestionsContainerProps> = ({
  questionEntry,
  userAnswers,
  children,
  onAnswersChange,
}) => {
  const { type, text } = questionEntry;

  return (
    <div css={questionContainer}>
      <QuestionTitle type={type} text={text} />
      <QuestionsContainer
        questionEntry={questionEntry}
        userAnswers={userAnswers}
        onAnswersChange={onAnswersChange}
      />
      {children}
    </div>
  );
};

export default QuestionsTypesContainer;
