import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const replyOptionsStyles = ({ units: { assignment } }, { hasContent }) => css`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .select-answer-text {
    margin-top: ${hasContent ? "1rem" : "0"};
    color: ${assignment.textColor};
  }

  .options-container {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;

    .reply-option {
      min-width: 188px;
      width: 188px;
    }
  }

  .divider {
    margin: 1rem 0;
  }

  .cancel-btn {
    margin: auto;
  }
`;

export const answerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  .edit-answer-btn {
    margin: auto;
  }
`;

export const answerContainerStyles = css`
  .image-container {
    height: 400px;
    margin: auto;

    ${mq["md"]} {
      max-width: 80%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .file-container {
    display: block;

    .file-wrapper {
      height: 459px;
      max-width: 687px;
      margin: auto;
    }
  }

  .file-card-container {
    max-width: 300px;
    margin: auto;
  }

  .video-player {
    max-width: 100%;
  }

  .audio-player {
    width: 100%;
    height: 55px;

    & > div {
      height: inherit;
    }
  }

  .container {
    display: flex;
    justify-content: center;
  }
`;
