import React, { FC, useState, useEffect } from "react";
import { LikertScaleAnswer, LikertScaleChildren } from "types/entities/Question";
import LikertScaleList from "./LikertScaleList";
import LikertScaleOption from "./LikertScaleOption";
import { useApplyTranslations } from "@hooks";

type LikertScaleType = {
  radioId: number;
  options: LikertScaleChildren;
  hasCustomAnswers: boolean;
  userAnswers: LikertScaleAnswer[];
  onAnswersChange: (answers: LikertScaleAnswer[]) => void;
};

const getRadioButtonChecked = (
  userAnswers: LikertScaleAnswer[],
  radioId: number,
  selectedAnswer: string,
  answerIndex: number,
  selectedAnswerIndex: number,
): boolean => {
  return userAnswers.some((answer) => {
    return (
      answer.id === radioId &&
      answer.user_answer === selectedAnswer &&
      selectedAnswerIndex === answerIndex
    );
  });
};

const LikertScale: FC<LikertScaleType> = ({
  radioId,
  options,
  hasCustomAnswers,
  onAnswersChange,
  userAnswers,
}) => {
  const { t } = useApplyTranslations();
  const optionsToMap = options.answers.possible;
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);

  //The initial mapping of the answers
  useEffect(() => {
    if (selectedAnswerIndex == -1 && userAnswers.length) {
      const initialAnswerIndex = optionsToMap.findIndex((option) =>
        userAnswers.some((value) => value.user_answer === option && value.id === radioId),
      );
      setSelectedAnswerIndex(initialAnswerIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers]);

  const handleRadioButtonChange = (value: string, index: number): void => {
    const foundAnswerIndex = userAnswers.findIndex((answer) => answer.id === radioId);

    const newUserAnswers =
      foundAnswerIndex === -1
        ? userAnswers.concat({
            id: radioId,
            user_answer: value,
          })
        : [
            ...userAnswers.slice(0, foundAnswerIndex),
            {
              ...userAnswers[foundAnswerIndex],
              user_answer: value,
            },
            ...userAnswers.slice(foundAnswerIndex + 1),
          ];

    setSelectedAnswerIndex(index);

    onAnswersChange([...newUserAnswers]);
  };

  return (
    <LikertScaleList>
      {optionsToMap.map((option, index) => {
        const answer = hasCustomAnswers ? option : t(`survey.predefinedAnswers.${option}`);
        const isChecked = getRadioButtonChecked(
          userAnswers,
          radioId,
          option,
          index,
          selectedAnswerIndex,
        );
        const id = `${radioId.toString()}-${index}`;

        return (
          <LikertScaleOption
            key={id}
            id={id}
            index={index}
            label={answer}
            value={option}
            isChecked={isChecked}
            onChange={(): void => handleRadioButtonChange(option, index)}
          />
        );
      })}
    </LikertScaleList>
  );
};

export default LikertScale;
