import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["subscription"] => {
  return {
    headerBackground: colors.secondary.lighter,
    headerText: colors.primary.base,
    plans: {
      textColor: colors.secondary.base,
      priceColor: colors.primary.base,
      borderColor: colors.secondary.light,
      borderColorSelected: Color(colors.primary.lightest).alpha(0.5).string(),
      toggleButtonColor: Color(colors.primary.lightest).alpha(0.25).string(),
      soonTag: Color(colors.green.light).alpha(0.15).string(),
    },
  };
};
