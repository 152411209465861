import { css, SerializedStyles } from "@emotion/react";
import {
  DroidSansWoff,
  DroidSansTtf,
  DroidSansEot,
  MuliWoff,
  MuliTtf,
  MuliEot,
} from "@assets/fonts";
import { CustomTheme } from "@styles/themes/defaultBlue";
import { mq } from "@utils/breakpoints";

export const GlobalStyleVariables = {
  mainDrawerMinWidth: "16rem",
  unitsMaxWidth: "1000px",
  unitsSidebarMaxWidth: "25rem",
};

export default ({ scrollbar, editor, icon, general }: CustomTheme): SerializedStyles => {
  return css`
    @font-face {
      font-family: "Droid Sans";
      font-style: normal;
      font-weight: 400;
      src: url("${DroidSansWoff}") format("woff"), url("${DroidSansTtf}") format("woff"),
        url("${DroidSansEot}") format("eot");
    }
    @font-face {
      font-family: "Muli";
      font-style: normal;
      font-weight: 400;
      src: url("${MuliWoff}") format("woff"), url("${MuliTtf}") format("truetype"),
        url("${MuliEot}") format("eot");
    }

    // Fix for the empty bottom space of the first step.
    #react-joyride-step-0 {
      position: absolute;
      top: 0;
    }

    html,
    body {
      /* Works on Firefox */
      scrollbar-width: 0.5rem;
      scrollbar-color: ${scrollbar.color} ${scrollbar.background};

      /* Works on Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      *::-webkit-scrollbar-track {
        background: ${scrollbar.background};
        border-radius: 10px;
      }

      *::-webkit-scrollbar-thumb {
        background-color: ${scrollbar.color};
        border-radius: 10px;
      }
    }

    // This is for the dropdown role cell in the table. Since its using portals the styles cannot be applied.
    .option-md.selected {
      background: ${general.primary} !important;
    }

    .js-focus-visible {
      &:focus::not(.focus-visible) {
        outline: none;
      }
    }

    /* Small devices (landscape phones, 576px and up) */
    ${mq["sm"]} {
    }

    /* Medium devices (tablets, 768px and up) */
    ${mq["md"]} {
    }

    /* Large devices (desktops, 992px and up) */
    ${mq["lg"]} {
    }

    /* Extra large devices (large desktops, 1200px and up) */
    ${mq["xl"]} {
    }

    #not-supported-browser-container {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 763px;
      margin: auto;
      padding: 16px;

      h3 {
        margin-top: 48px;
        text-align: center;
        font-family: Mulish, Arial, sans-serif;
        color: rgb(35, 35, 35);
        line-height: 1.5715;
        font-size: 1.266rem;
        font-weight: 400;
      }
    }

    .icon-danger {
      color: ${icon.danger} !important;
    }

    .tooltip {
      word-break: break-word;
    }

    /*************************/
    /* FROALA STYLES         */
    /*************************/

    // Used this styles to emulate TalentCraft styles
    .fr-view {
      word-break: break-word;
      font-family: Mulish, Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.8;
    }

    .fr-view pre {
      padding: 0.5rem;
      background-color: ${editor.background};
      border-radius: 5px;
    }

    // fix h1 styles that are being overwritten by jsdom library styles
    .fr-view h1 {
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
    }

    .fr-wrapper .fr-placeholder {
      font-family: Mulish, Arial, sans-serif;
    }

    // Override default styles
    .fr-toolbar.fr-inline {
      background: ${editor.moreBackground};
      border: 1px solid ${editor.borderColor};
      border-radius: 5px;

      .fr-btn-grp {
        margin: 0;
        &:first-of-type {
          margin-inline: 12px 0;
        }
      }

      .fr-more-toolbar {
        background: ${editor.background};
        border-bottom: 1px solid ${editor.borderColor};
      }

      /* command btns */

      .fr-btn:last-child:not([data-cmd="html"]) {
        margin-inline-end: 12px;
      }

      .fr-command {
        &:hover:not(.fr-table-cell) {
          background: ${editor.hoverColor};
        }

        &.fr-btn.fr-disabled {
          color: ${editor.disabledColor} !important; /* to not overwritten by hover color */
          opacity: 1;
        }

        &.fr-btn.fr-dropdown.fr-active,
        &.fr-btn.fr-btn-active-popup {
          background: ${editor.hoverColor};
          color: ${editor.focusColor};
        }

        &.fr-btn.fr-active {
          color: ${editor.focusColor};
          &:after {
            border-bottom-color: ${editor.focusColor};
          }
        }

        &.fr-btn.fr-open {
          margin-top: 4px;
          margin-bottom: 4px;
          color: ${editor.focusColor};
          &:not(:hover):not(:focus):not(:active) {
            background: transparent;
          }
        }
      }

      // Add custom styles
      /* custom button used as horizontal divider */
      button[data-cmd="horizontalDivider"] {
        margin-inline: 14px !important;
        cursor: initial !important;

        .custom-pipe {
          width: 1px;
          min-width: 1px !important;
          padding: 0 !important;
          height: 20px !important;
          line-height: 20px !important;

          &:before {
            content: "";
            border-left: 1px solid currentColor;
            font-size: 20px;
          }
        }
      }
    }

    /* text and background select color dropdown erase icon styles */
    .fr-command.fr-select-color .fa-kit.fa-arrow-rotate-left,
    .fr-color-set.fr-table-colors .fr-command .fa-kit.fa-eraser {
      line-height: 24px;
    }

    /* styles for all custom icons */
    .fa-kit {
      font-size: 1rem !important;
    }

    .fr-desktop .fr-command:hover:not(.fr-table-cell) {
      background: ${editor.hoverColor};
    }

    /* toolbar dropdown list styles */
    .fr-command.fr-btn
      + .fr-dropdown-menu
      .fr-dropdown-wrapper
      .fr-dropdown-content
      ul.fr-dropdown-list
      li
      a {
      &:hover {
        background: ${editor.hoverColor};
      }

      &.fr-active {
        background: transparent;
        color: ${editor.focusColor};
        &:hover {
          background: ${editor.hoverColor};
        }
      }

      .fa-kit {
        margin: auto;
        padding-block: 7px;
      }
    }

    .fr-popup {
      // bigger z-index that Drawer component
      z-index: 1001 !important;

      .fr-command.fr-btn.fr-dropdown.fr-active {
        background: ${editor.hoverColor};
        color: ${editor.focusColor};

        &:hover {
          background: ${editor.hoverColor};
        }

        &:after {
          border-bottom-color: ${editor.focusColor};
        }
      }

      .fr-command.fr-btn.fr-dropdown:after {
        top: 50%;
        transform: translateY(-50%);
      }

      .fr-tabs .fr-command.fr-btn {
        height: 49px;
        i {
          margin-inline: 11px;
        }
      }
    }

    // Quick insert buttons
    .fr-quick-insert {
      padding-left: 0; // to overwrite default style
      inset-inline-start: 1rem !important;
    }

    .fr-qi-helper {
      padding-left: 0;
      inset-inline-start: 4.5rem !important;
    }

    .fr-qi-helper a.fr-btn.fr-floating-btn {
      padding: 0px 10px;
    }
  `;
};
