import { css } from "@emotion/react";

export const tocHeader = ({ unitsToC, courseToc }, hasProgressBar) => css`
  margin: ${hasProgressBar && "0 0 2.5rem"};
  padding-inline-end: 2.25rem;

  &.admin {
    padding: 0;
    margin: 0;

    .header-container {
      padding: 0.5rem;
      margin-bottom: 1rem;

      &.is-selected {
        border: 1px solid ${courseToc.edit.selected};
        border-radius: 5px;
      }
    }
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .link {
      display: flex;
      align-items: center;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    background-color: ${unitsToC.headerBackgroundColor};

    .content-container {
      display: flex;
      align-items: center;

      img {
        width: auto;
        height: 57px;
        border-radius: 5px;
        margin-inline-end: 0.5rem;
      }

      .title {
        line-height: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
        color: ${unitsToC.titleColor};
      }
    }

    .actions-container {
      display: flex;
      align-items: center;
    }
  }

  .back-link-wrapper {
    max-width: 18rem;
    word-break: break-all;
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const tocListContainer = css`
  margin: 0 0 0.5rem;

  .list-title-container {
    margin-bottom: 1rem;
  }
`;

export const listItemContainer = ({ unitsToC }, { maxLines }) => css`
  margin: 0 0 0.5rem 0;

  a {
    display: flex;
    color: ${unitsToC.titleColor};

    &:hover {
      color: ${unitsToC.hoverColor};
    }
  }

  div {
    display: inline-flex;
  }

  .list-item-container {
    display: inline-flex;
    align-items: center;

    &.disabled {
      color: ${unitsToC.sequentialColor};

      svg {
        fill: ${unitsToC.sequentialColor};
      }
    }

    &.is-active {
      font-weight: 700;
    }

    .list-item {
      margin-inline-start: 0.5rem;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${maxLines};
      -webkit-box-orient: vertical;
    }
  }
`;
