import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { GlobalStyleVariables } from "@styles";

const { unitsSidebarMaxWidth } = GlobalStyleVariables;

export const tocContainerStyles = ({ unitsToCNew }) => css`
  width: 100%;
  height: 100vh;
  border-inline-end: 2px solid ${unitsToCNew.borderColor};
  background: ${unitsToCNew.backgroundColor};

  &.is-closed {
    cursor: pointer;
  }
`;

export const tocStyles = () => css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const tocTriggerContainerStyles = ({ unitsToCNew: { trigger } }, { isOpen }) => css`
  .toc-trigger {
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    inset-inline-start: 0;
    background-color: ${trigger.backgroundColor};
    border-color: ${trigger.backgroundColor};
    height: 3rem;
    width: 1.5rem;
    min-width: 0;
    padding-inline: 0 0.25rem;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    inset-inline-start: 0;
    visibility: ${isOpen ? "hidden" : "visible"};
    border-inline-end: 1px solid ${trigger.borderColor};
    border-block: 1px solid ${trigger.borderColor};

    ${mq["lg"]} {
      inset-inline-start: ${isOpen ? unitsSidebarMaxWidth : "4.5rem"};
      visibility: visible;
    }

    &:hover {
      background-color: ${trigger.backgroundColor};
      border-inline-start-color: ${trigger.backgroundColor};
      border-inline-end-color: ${trigger.borderColor};
      border-block-color: ${trigger.borderColor};
    }
  }
`;

export const tocTriggerContainerMobileStyles = ({ icon }) => css`
  position: absolute;
  z-index: 9999;
  top: 0.5rem;

  .close-icon {
    color: ${icon.white};
  }
`;

export const tocDrawerBodyContainer = ({ unitsToCNew }) => css`
  background: ${unitsToCNew.backgroundColor};
  height: 100%;
`;
