import { PalletThemeColors, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["unitsToC"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    titleColor: colors.black,
    sequentialColor: colors.secondary.base,
    borderColor: colors.secondary.lighter,
    hoverColor: isLightTheme ? colors.primary.dark : colors.primary.base,
    completedColor: colors.green.base,
    failedColor: colors.red.base,
    headerBackgroundColor: colors.white,
  };
};
