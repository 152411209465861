import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const iltContainer = css`
  height: 100%;

  .title,
  .unit-description,
  .no-sessions-text {
    text-align: center;
  }

  .title,
  .unit-description {
    margin-bottom: 1rem;
  }

  .session-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 3px #00000029;
    border: 1px solid #eceeef;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .session-container {
    margin-bottom: 1rem;

    &:last-of-type {
      margin: 0;
    }

    .session-description {
      word-break: break-word;
    }
  }

  .multi-session-header {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: center;

    ${mq["md"]} {
      justify-content: space-between;
    }

    .multi-session-header-title {
      word-break: break-word;

      ${mq["md"]} {
        margin-inline-start: 3.75rem;
      }
    }
  }

  .body {
    ${mq["md"]} {
      display: flex;
    }
  }

  .session-details {
    width: 100%;
  }

  .date {
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    text-align: center;
    margin: auto;
    max-width: 4rem;
    margin-bottom: 1rem;

    ${mq["md"]} {
      max-width: auto;
      margin: initial;
      margin-bottom: 0;
      margin-inline-end: 1rem;
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    ${mq["md"]} {
      justify-content: space-between;
    }

    .header-title {
      word-break: break-word;

      ${mq["md"]} {
        margin-inline-end: 1rem;
      }
    }
  }

  .info-container {
    margin-top: 1rem;

    .time,
    .instructor,
    .capacity,
    .location {
      display: flex;
      align-items: center;

      &:last-of-type {
        margin: 0;
      }

      svg,
      .avatar {
        margin-inline-end: 0.5rem;
      }

      svg {
        width: 30px;
      }

      .info {
        display: flex;
        flex-direction: column;
      }
    }

    .capacity {
      .registered-users-full {
        color: red;
      }
    }

    .webinar {
      display: flex;
      margin-inline-end: 1rem;
    }

    .join-btn {
      white-space: nowrap;
    }
  }

  .register-btn-mobile {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
`;
