import React, { FC, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import { useResponsive } from "ahooks";
import classNames from "classnames";
import { Button, Heading } from "@epignosis_llc/gnosis";
import { CheckSolidSVG, CloseSolidSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { topNavContainer } from "../../../layouts/UnitLayout/components/styles";
import { URLS } from "@constants/urls";
import { MyUnit, TestUnit, Course } from "types/entities";
import { Announcements } from "@components/ReusableComponents";
import { useIsPublicCourse } from "@hooks";

export type TopNavProps = {
  unit: MyUnit | TestUnit;
  courseProgress?: Course["progress"];
  rightMenuItems?: ReactNode[];
  title?: ReactNode;
};

const completionLinkClassNames = (unitId: string): string =>
  classNames({
    "is-pressed": unitId === "completed",
  });

const TopNav: FC<TopNavProps> = ({ unit, courseProgress, rightMenuItems, title }) => {
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };
  const { sm } = useResponsive();
  const completionStatus = courseProgress?.completion_status;
  const isFailed = completionStatus === "failed";
  const isCompleted = completionStatus === "completed" || isFailed;
  const inCompletionScreen = unitId === "completed";

  const isPublicCourse = useIsPublicCourse();

  return (
    <nav css={(theme): SerializedStyles => topNavContainer(theme, isFailed)}>
      <div className="nav-content">
        {!title && (
          <Heading as="h1" size={sm ? "lg" : "md"}>
            {unit?.name}
          </Heading>
        )}

        {title && title}

        {!isPublicCourse && (
          <div className="right-container">
            {rightMenuItems ?? []}
            {isCompleted && (
              <Button
                as={Link}
                to={!inCompletionScreen ? URLS.user.createCourseResultsLink({ courseId }) : "#"}
                variant="ghost"
                rounded
                className={completionLinkClassNames(unitId)}
                aria-label="Go to Results"
              >
                {isFailed ? <CloseSolidSVG height={24} /> : <CheckSolidSVG height={24} />}
              </Button>
            )}
          </div>
        )}
      </div>

      {!isPublicCourse && <Announcements />}
    </nav>
  );
};

export default TopNav;
