import { Variants } from "framer-motion";

export const MAIN_BUTTON_TEXT = {
  completed: "myCourses.mainButtonText.revisitCourse",
  incomplete: "myCourses.mainButtonText.incomplete",
  not_attempted: "myCourses.mainButtonText.notAttempted",
  failed: "myCourses.mainButtonText.revisitCourse",
};

export const MAX_NUMBER_OF_CHARS = 643;

export const REACT_PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
      crossOrigin: "true",
    },
  },
};

export const hoverContainerVariants: Variants = {
  rest: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
};
