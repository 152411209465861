import { css } from "@emotion/react";

export const defaultFooterContainer = css`
  display: flex;

  .btn-nav {
    margin-inline-end: 1rem;

    &.hidden {
      visibility: hidden;
    }
  }

  .btn-complete-wrapper {
    button {
      height: auto;
      min-height: 2.5rem;
    }
  }
`;

export const footerInfoTextContainer = css`
  padding: 1rem;
  text-align: center;
`;
