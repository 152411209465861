import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const scormIFrameContainer = (width, height) => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  #scorm-frame {
    border: none;
    width: 100%;
    max-width: ${width ? `${width}px` : "100%"};
    height: ${height ? `${height}px` : "660px"};
  }
`;

export const scormPopUpContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 860px;
  border: none;
  border-radius: 10px;
  padding: 2rem;
  overflow-y: auto;

  ${mq["sm"]} {
    border: 1px solid #d8d8d8;
  }

  .pop-up-content-wrapper {
    text-align: center;
    max-width: 460px;

    .pop-up-content-image {
      margin-bottom: 2rem;
    }

    .pop-up-content-text {
      padding-bottom: 0.5rem;
    }

    .pop-up-content-button {
      margin-top: 1.5rem;
    }
  }
`;
