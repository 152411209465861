import React, { FC, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Text } from "@epignosis_llc/gnosis";
import { GripDotsSVG } from "@epignosis_llc/gnosis/icons";
import { orderingContainer } from "./styles";
import { OrderingQuestion } from "types/entities";
import { reorder } from "@utils/helpers";

type OrderingContainerProps = {
  questionEntry: OrderingQuestion;
  onAnswersChange: (answers: string[]) => void;
  userAnswers: string[];
};

const OrderingContainer: FC<OrderingContainerProps> = ({
  questionEntry,
  onAnswersChange,
  userAnswers,
}) => {
  const answers = questionEntry.user_answers
    ? questionEntry.user_answers.possible
    : questionEntry.answers.possible;
  const [listItems, setListItems] = useState(Object.values(answers));

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (result.destination) {
      const items = reorder(listItems, result.source.index, result.destination.index);
      setListItems(items);
      onAnswersChange(items);
    }
  };

  const list = userAnswers.length > 0 ? userAnswers : listItems;

  return (
    <form css={orderingContainer}>
      <div className="answers-content-container ordering">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided): JSX.Element => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="ordering-wrapper"
              >
                {list.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided): JSX.Element => (
                      <div
                        className="draggable-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <GripDotsSVG height={32} className="grip-icon" />
                        <Text fontSize="md" className="draggable-item-text">
                          {item}
                        </Text>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </form>
  );
};
export default OrderingContainer;
