import React, { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { SerializedStyles } from "@emotion/react";
import { OpenedMessageSVG } from "@epignosis_llc/gnosis/icons";
import { Button, Tooltip } from "@epignosis_llc/gnosis";

// Styles
import { MessagesMenuStyles } from "./MessagesMenu.styles";

// API, Permissions, hooks...
import permissions from "@utils/permissions";
import { useApplyTranslations } from "@hooks";
import { getUnreadMessages } from "@api/messages";

// Constants
import { URLS } from "@constants/urls";
import queryKeys from "@constants/queryKeys";

const BranchesMenu: FC = () => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();
  // Set permissions
  const { canAccessMessages } = permissions.messagesPermissions;

  // Get total unread messages
  const { data } = useQuery(queryKeys.messages.unreadMessages, getUnreadMessages, {
    enabled: canAccessMessages(),
  });

  return (
    <div css={(theme): SerializedStyles => MessagesMenuStyles(theme)}>
      <Tooltip content={t("mainMenu.messages")}>
        <Button
          data-testid="topbar-messages"
          variant="ghost"
          className="messages action-btn"
          color="primary"
          noGutters
          onClick={(): void => navigate(URLS.user.messages)}
        >
          {data && data._data.total > 0 && <div className="unread-message" />}
          <OpenedMessageSVG height={32} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default BranchesMenu;
