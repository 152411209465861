import { css } from "@emotion/react";

export const AssignmentInfoContainer = (theme) => {
  return css`
    display: flex;
    gap: 2.3125rem;
    padding: 0.75rem;

    .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.75rem;

      .grade-info {
        display: flex;
        flex-direction: row;
        gap: 5rem;
      }
    }

    .info-nudge {
      display: flex;
      flex-direction: column;
      .field {
        line-height: normal;
        color: ${theme.assignment.drawer.infoField};
      }
    }
  `;
};

export const TabContainer = () => {
  return css`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    img {
      width: 100%;
    }

    .pdf-wrapper {
      height: 52rem;
    }

    .card-wrapper {
      width: 15rem;
    }

    .download-button {
      width: fit-content;
    }
  `;
};

export const assignmentStatusStyles = () => {
  return css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    .status {
      white-space: nowrap;
    }
  `;
};
