import React, { useState, useEffect, FC, useLayoutEffect } from "react";
import { FillTheGapQuestion } from "types/entities";
import { getFillTheGapText } from "@utils/helpers";
import { FillTheGapResult } from "@utils/helpers/question";

export type FillTheGapContainerProps = {
  questionEntry: FillTheGapQuestion;
  userAnswers: {
    [key: string]: string;
  };
  onAnswersChange: (answers: { [key: string]: string }) => void;
};

const FillTheGapContainer: FC<FillTheGapContainerProps> = ({
  questionEntry,
  onAnswersChange,
  userAnswers,
}) => {
  const { html } = questionEntry.text;
  const { gaps } = questionEntry.answers;
  const [question, setQuestion] = useState<FillTheGapResult | null>(null);

  useEffect(() => {
    setQuestion(getFillTheGapText(html, gaps, userAnswers, onAnswersChange));
  }, [html, gaps, userAnswers, onAnswersChange]);

  useLayoutEffect(() => {
    if (question) {
      const { JSXOptions } = question ?? {};

      // Render the hidden select elements to the correct position
      Object.keys(JSXOptions)?.forEach((key) => {
        const select = document.getElementById(`select-container-${key}`);
        const spanPosition = document.getElementById(`position-${key}`);
        if (select && spanPosition) {
          spanPosition.appendChild(select);
        }
      });
    }
  }, [question]);

  return (
    <>
      <form>
        <div className="answers-content-container">
          {question && <div dangerouslySetInnerHTML={{ __html: question?.replacedContent }} />}
        </div>
      </form>
      {question && (
        // visibility: hidden (not an error), Render select elements as hidden in order
        // to do DOM manipulation and  move those elements to the correct position
        <span style={{ visibility: "hidden" }}>
          {Object.keys(question?.JSXOptions)?.map((key) => (
            <span key={`select-container-${key}`} id={`select-container-${key}`}>
              {question?.JSXOptions[key]}
            </span>
          ))}
        </span>
      )}
    </>
  );
};

export default FillTheGapContainer;
