import { css } from "@emotion/react";

export const publicCourseContainer = css`
  text-align: center;
  margin-top: 2rem;

  .sign-up-btn {
    margin: 1rem 0;
  }

  .social-buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    .social-link {
      line-height: 0;
    }
  }
`;
