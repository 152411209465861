import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { unitListItem } from "./styles";
import { Course, MyUnit } from "types/entities";
import { URLS } from "@constants/urls";
import { useIsExternalView } from "@hooks";
import CourseTocItemContent from "./CourseTocItemContent";
import { SerializedStyles } from "@emotion/react";

export type CourseTocItemProps = {
  course: Course;
  unit: MyUnit;
  isActive: boolean;
  isReadonly: boolean;
  isLearnerView?: boolean;
  isMandatory?: boolean;
};

type getTocItemLinkProps = {
  courseId: string;
  unitId: string;
  isSampleUnit: boolean;
  isExternalView: boolean;
  isLearnerView?: boolean;
};

const containerClassNames = (
  isActive: boolean,
  isReadonly: boolean,
  isSampleUnit: boolean,
): string =>
  classNames({
    active: isActive || isSampleUnit,
    readonly: isReadonly && !isSampleUnit,
  });

const getTocItemLink = ({
  courseId,
  unitId,
  isSampleUnit,
  isLearnerView,
  isExternalView,
}: getTocItemLinkProps): string => {
  const sampleUnitLink = isExternalView
    ? URLS.publicCourse.createSampleUnitLink({ courseId, unitId })
    : URLS.catalog.createSampleUnitLink({ courseId, unitId });

  if (!isLearnerView) return URLS.units.getUnitEditLink({ courseId, unitId });

  return isSampleUnit ? sampleUnitLink : URLS.user.createUnitLink({ courseId, unitId });
};

const CourseTocItem: FC<CourseTocItemProps> = ({
  course,
  unit,
  isActive,
  isReadonly,
  isLearnerView,
  isMandatory = false,
}) => {
  const navigate = useNavigate();
  const isExternalView = useIsExternalView();

  const { availability } = course;
  const { id, progress, type, name, policies, sample } = unit;
  const { status = "unknown" } = progress ?? {};

  const courseId = course.id.toString();
  const unitId = id.toString();
  const isSampleUnit = isLearnerView ? sample && !availability : sample;
  const { can_view: canView = false } = policies ?? {};

  const navigateToUnit = (): void => {
    const tocItemLink = getTocItemLink({
      courseId,
      unitId,
      isSampleUnit,
      isExternalView,
      isLearnerView,
    });

    navigate(tocItemLink);
  };

  // Instructor/admin view
  if (!isLearnerView) {
    return (
      <dd
        css={(theme): SerializedStyles => unitListItem(theme)}
        className="active"
        data-testid="toc-item"
      >
        {!canView ? (
          <CourseTocItemContent
            status={status}
            type={type}
            name={name}
            isReadonly={isReadonly}
            isLearnerView={isLearnerView}
            isMandatory={isMandatory}
            isSampleUnit={isSampleUnit}
          />
        ) : (
          <div
            className="list-item-link"
            key={unitId}
            title={name}
            data-testid="active-list-item"
            onClick={navigateToUnit}
          >
            <CourseTocItemContent
              status={status}
              type={type}
              name={name}
              isReadonly={isReadonly}
              isLearnerView={isLearnerView}
              isMandatory={isMandatory}
              isSampleUnit={isSampleUnit}
            />
          </div>
        )}
      </dd>
    );
  }

  // Learner view
  return (
    <dd
      css={(theme): SerializedStyles => unitListItem(theme)}
      className={containerClassNames(isActive, isReadonly, isSampleUnit)}
      data-testid="toc-item"
    >
      {/* If the unit is not sample but its readonly or inactive */}
      {!isSampleUnit && (isReadonly || !isActive) ? (
        <CourseTocItemContent
          status={status}
          type={type}
          name={name}
          isReadonly={isReadonly}
          isLearnerView={isLearnerView}
          isMandatory={isMandatory}
        />
      ) : (
        <div
          className="list-item-link"
          key={unitId}
          title={name}
          data-testid="active-list-item"
          onClick={navigateToUnit}
        >
          <CourseTocItemContent
            status={status}
            type={type}
            name={name}
            isReadonly={isReadonly}
            isLearnerView={isLearnerView}
            isSampleUnit={isSampleUnit}
            isMandatory={isMandatory}
          />
        </div>
      )}
    </dd>
  );
};

export default CourseTocItem;
