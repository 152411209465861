// Packages
import React, { FC } from "react";

// Components
import { Text, MediaPlayer } from "@epignosis_llc/gnosis";
import { FileCard, FileFailed, FileProcessing } from "@components";

// Utils
import { useApplyTranslations } from "@hooks";

// Styles
import { answerContainerStyles } from "./styles";

// Others
import { File, RichText } from "types/entities";
import { useConfigurationStore } from "@stores";

type AnswerContainerProps = {
  answerType: "text" | "file";
  textAnswer?: RichText;
  file?: File;
  isCardReadonly?: boolean;
};

const config = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
    },
  },
};

const AnswerContainer: FC<AnswerContainerProps> = ({
  answerType,
  textAnswer,
  file,
  isCardReadonly = true,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const { upload_limits: uploadLimits } = domainSettings ?? {};

  const { mime_types: audioMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.audio"]) ?? {};
  const { mime_types: documentMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.document"]) ?? {};
  const { mime_types: zipMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.zip"]) ?? {};
  const { mime_types: videoMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.video"]) ?? {};
  const { mime_types: imageMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.image"]) ?? {};

  const showTextAnswer = answerType === "text";
  const isFileReady = file?.status === "ready";

  const fileMimeTypes = [...documentMimeTypes, ...zipMimeTypes];
  const showFile = isFileReady && fileMimeTypes.includes(file.mime_type);
  const showAudioPlayer = isFileReady && audioMimeTypes.includes(file.mime_type);
  const showVideoPlayer = isFileReady && videoMimeTypes.includes(file.mime_type);
  const showImage = isFileReady && imageMimeTypes.includes(file.mime_type);

  const showAudioVideoProcessing = file?.status === "processing";
  const showFileProcessingFailed = file?.status === "failed";

  return (
    <div css={answerContainerStyles}>
      {showAudioVideoProcessing && (
        <FileProcessing info={t("assignment.fileUnderProcessDescription")} />
      )}

      {showFileProcessingFailed && <FileFailed info={t("errors.units.fileProcessingSubtitle")} />}

      {showTextAnswer && textAnswer && (
        <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: textAnswer.html }} />
      )}

      {showFile &&
        file &&
        (file?.mime_type === "application/pdf" ? (
          <div className="file-container">
            <div className="file-wrapper">
              <iframe src={file.url} height="100%" width="100%" />
            </div>
          </div>
        ) : (
          <div className="file-card-container">
            <FileCard {...file} isReadonly={isCardReadonly} />
          </div>
        ))}

      {showImage && (
        <div className="image-container">
          <img src={file?.url} />
        </div>
      )}

      {(showAudioPlayer || showVideoPlayer) && (
        <div className="container">
          <div className={showAudioPlayer ? "audio-player" : "video-player"}>
            <MediaPlayer
              type={showAudioPlayer ? "audio" : "video"}
              src={file?.url}
              controls
              config={config}
              height="100%"
              width="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerContainer;
