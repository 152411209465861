import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { FooterButtonStyles } from "./styles";
import { MyUnit } from "types/entities";
import { useApplyTranslations } from "@hooks";

export type FooterButtonProps = React.HTMLProps<HTMLButtonElement> & {
  unit: MyUnit;
  name?: string;
  isLoading?: boolean;
  disabled?: boolean;
  buttonText?: string;
  children?: never;
  onClick?: () => void;
};

const FooterButton: FC<FooterButtonProps> = ({
  unit,
  name,
  isLoading = false,
  disabled = false,
  buttonText,
  onClick: onClick,
}) => {
  const { t, i18n } = useApplyTranslations();
  const { progress } = unit;
  const isCompleted = progress?.status === "completed";
  const isRtl = i18n.dir() === "rtl";

  return (
    <Button
      css={FooterButtonStyles}
      name={name ?? "Complete unit"}
      onClick={onClick}
      isLoading={isLoading}
      aria-label={isCompleted ? `Unit "${unit.name}" is completed` : ""}
      className={isCompleted ? "completed" : ""}
      iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
      disabled={disabled}
    >
      {buttonText ? buttonText : t("general.continue")}
    </Button>
  );
};

export default FooterButton;
