import React, { ChangeEvent, FC } from "react";
import { Textarea } from "@epignosis_llc/gnosis";
import { FreeTextQuestion } from "types/entities";
import { useApplyTranslations } from "@hooks";

type FreeTextContainerProps = {
  questionEntry: FreeTextQuestion;
  userAnswers: string[];
  onAnswersChange: (answers: string[]) => void;
};

const FreeTextContainer: FC<FreeTextContainerProps> = ({ onAnswersChange, userAnswers }) => {
  const { t } = useApplyTranslations();
  return (
    <form autoComplete="off">
      <div className="answers-content-container">
        <Textarea
          id="answer"
          label={t("general.answers", { count: 1 })}
          placeholder={t("test.writeYourAnswer")}
          value={userAnswers[0] ?? ""}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
            onAnswersChange([e.target.value]);
          }}
        />
      </div>
    </form>
  );
};

export default FreeTextContainer;
