import React, { FC } from "react";
import {
  baseLikertScaleContainer,
  likertScaleContainerDesktop,
  likertScaleContainerMobile,
} from "./styles";
import { useResponsive } from "ahooks";

type LikertScaleListProps = {
  customBreakpoint?: boolean;
};

const LikertScaleList: FC<LikertScaleListProps> = ({ customBreakpoint = false, children }) => {
  const { lg, md } = useResponsive();
  const isMobile = customBreakpoint ? !lg : !md;
  // used styles this way because print media does not support screen size
  const styles = isMobile ? likertScaleContainerMobile : likertScaleContainerDesktop;

  return <ul css={[baseLikertScaleContainer, styles]}>{children}</ul>;
};

export default LikertScaleList;
