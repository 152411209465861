import { css } from "@emotion/react";

export const footerWrapper = ({ unitFooter }) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${unitFooter.borderColor};
  padding: 1rem;

  button,
  a {
    svg {
      height: 1.5rem;
    }
  }
`;
