import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const sidebarEmptyStateStyles = css`
  .empty-state-loader {
    margin-bottom: auto;
    margin-inline: 1rem 0;
    margin-block: 1rem 0;
    padding-inline-end: 1rem;
    flex: 1 1 0;
    position: relative;
    margin-bottom: auto;
  }

  .empty-state-container {
    margin-inline: 1rem 0;
    margin-block: 1rem 0;
    padding-inline-end: 1rem;
    flex: 1 1 0;
    position: relative;

    .no-units {
      h3,
      p {
        font-size: ${typeScale["sm"]};
      }
    }
  }
`;

export const uploadLoaderStyles = css`
  visibility: visible;
  margin: 0 0 0.5rem 0;
  border-radius: 5px;
  border: 1px solid;
  border-color: transparent;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
`;

export const unitListItemStyles = ({ unitsToCNew: { listItem }, icon }) => css`
  &.is-reordered {
    background-color: ${listItem.hoverColor};
  }

  .list-title {
    padding: 0;
    height: auto;
    margin-inline-start: 0;
  }

  .unit-dropdown-options {
    margin-inline-end: 0.5rem;
  }

  .dropdown-list-item {
    &.delete {
      color: ${icon.danger};
    }

    &.publish-option {
      color: ${icon.primary};
    }
  }
`;

export const unitIconWrapperStyles = css`
  display: inline-flex;

  .unit-reorder-checkbox {
    width: 20px;
    margin-inline: 6px;
    z-index: 9999;

    label:before {
      margin-inline-end: 0;
    }
  }

  .reorder-icon {
    width: 2rem;
  }

  .section-icon-placeholder {
    width: 2rem;
  }
`;

export const listItemActionsWrapperStyles = ({ courseEdit }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .linked-unit-tag-parent-container {
    & > div {
      line-height: 0;
    }

    .linked-unit-tag-parent {
      display: flex;
      align-items: center;
      gap: 0.3125rem;
      background: ${courseEdit.linkedUnit.tagBackground};
      padding: 3px 4px;
      border-radius: 5px;
      line-height: 0;

      .linked-unit-tag-parent-text {
        color: ${courseEdit.linkedUnit.tagTextColor};
      }
    }
  }

  .linked-unit-tag-child {
    display: flex;
    align-items: center;
    background: ${courseEdit.linkedUnit.tagBackground};
    padding: 1px 4px;
    border-radius: 5px;

    & > div {
      line-height: 0;
    }
  }
`;
