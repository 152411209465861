import React, { FC, useEffect, useState } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useLocation } from "react-router-dom";
import { startButtonStyles } from "../../styles";
import { useApplyTranslations, useIsExternalView, useSearchQuery } from "@hooks";
import { useConfigurationStore } from "@stores";
import localStorageKeys from "@constants/localStorageKeys";
import { CourseProgress } from "types/entities";
import { getCourseButtonText } from "../helpers";
import { Price } from "@components";

// TODO: fix import when units redesign is completed
import SubscriptionModal from "@components/Course/components/SubscriptionModal";
import ExternalSubscriptionModal from "@components/ReusableModals/ExternalSubscriptionModal";

type SubscribeButtonProps = {
  isDisabled: boolean;
  isUserEnrolled: boolean;
  completionStatus?: CourseProgress["completion_status"];
  isLoading: boolean;
  onEnroll: () => void;
};

// This is used for external views and enrollment after redirection
type SearchQueryFilters = {
  action: "enrollment" | "payment";
};

const SubscribeButton: FC<SubscribeButtonProps> = ({
  isDisabled,
  isUserEnrolled,
  completionStatus,
  isLoading,
  onEnroll,
}) => {
  const { t } = useApplyTranslations();
  const { userProfileData, catalogSettings } = useConfigurationStore();
  const isExternalView = useIsExternalView();
  const { state } = useLocation();
  const locationState = state as { isPayment?: boolean };
  const { action } = useSearchQuery() as SearchQueryFilters;

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isExternalSubscriptionModalOpen, setIsExternalSubscriptionModalOpen] = useState(false);

  const userHasActiveSubscription = userProfileData?.subscription?.status === "active";
  const subscriptionPrice = catalogSettings?.subscription.price ?? undefined;

  const getButtonText = (): JSX.Element | string => {
    if (!userHasActiveSubscription)
      return (
        <>
          {t("general.subscribe")} (<Price value={subscriptionPrice?.amount ?? 0} />)
        </>
      );

    if (!isUserEnrolled) return t("payments.subscriptions.addToSubscription");

    return getCourseButtonText(completionStatus);
  };

  const handleButtonClick = (): void => {
    if (isExternalView) {
      setIsExternalSubscriptionModalOpen(true);
      return;
    }

    // If the user has not active subscription
    if (!userHasActiveSubscription) {
      setIsSubscriptionModalOpen(true);
      return;
    }

    onEnroll();
  };

  // In this useEffect we check if the url parameters contain the action parameter "enrollment".
  // This is used when a user signs in or signs up from an external view and choosed to enroll in a course.
  // Also we check if the EXTERNAL_SIGNING_SIGNUP or PENDING_CATALOG_ENROLLMENT storage key exists in order to prevent the user from enrolling though url
  useEffect(() => {
    if (
      (action === "enrollment" || action === "payment") &&
      (localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP) ||
        localStorage.getItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT))
    ) {
      action === "enrollment" ? onEnroll() : handleButtonClick();
    }

    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
    localStorage.removeItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get the active bundle from history state and set the active bundle and on change action
  useEffect(() => {
    if (locationState?.isPayment) handleButtonClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <>
      <div css={startButtonStyles}>
        <Button
          color="primaryDarker"
          variant="outline"
          className="start-button"
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={handleButtonClick}
        >
          {getButtonText()}
        </Button>
      </div>

      {/* Only render the subscription modal if subscriptions are enabled,
      the course has price and the user does not have an active subscription already*/}
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={(): void => setIsSubscriptionModalOpen(false)}
        />
      )}

      {isExternalSubscriptionModalOpen && (
        <ExternalSubscriptionModal
          isOpen={isExternalSubscriptionModalOpen}
          onClose={(): void => setIsExternalSubscriptionModalOpen(false)}
        />
      )}
    </>
  );
};

export default SubscribeButton;
