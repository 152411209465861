import { css } from "@emotion/react";
import { blendWithBackground } from "@styles/utils";

export const AIButtonStyles = (theme) => css`
  --border-angle: 0turn; // starting-position
  --primary-color: ${blendWithBackground(
    theme.unitsToCNew.top.AIButton,
    theme.unitsToCNew.top.baseBackgroundColor,
  )}; // background
  --border-gradient-color1: #0881f9; // border-blue
  --border-gradient-color2: #f56d34; // border-orange
  --main-bg: conic-gradient(from var(--border-angle), var(--primary-color), var(--primary-color));
  --gradient-border: conic-gradient(
    from var(--border-angle),
    var(--border-gradient-color1) 0%,
    var(--border-gradient-color2) 50%,
    var(--border-gradient-color1) 100%
  );

  height: 40px;
  padding: 0 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  border: solid 2px transparent;
  cursor: pointer;
  background: var(--main-bg) padding-box, var(--gradient-border) border-box,
    var(--main-bg) border-box;
  animation: bg-spin 2.5s linear;
  animation-iteration-count: 1;

  &:hover {
    animation-play-state: running;
  }

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
`;
