import { t } from "@utils/i18n";
import { yup } from "./validation";
type ModeType = "all_users" | "branch" | "group" | "user";

export const EcommerceSettingsSchema = yup.object().shape({
  invoices: yup.object().shape({
    enabled: yup.boolean().required(),
    message: yup
      .string()
      .trim()
      .max(500, () => t("errors.general.valueTooLong", { count: 500 }))
      .when("enabled", {
        is: true,
        then: yup
          .string()
          .trim()
          .max(500, () => t("errors.general.valueTooLong", { count: 500 }))
          .required(),
        otherwise: yup.string().nullable(),
      }),
  }),
  global_discount: yup.object().shape({
    enabled: yup.boolean().required(),
    percentage: yup.number().when("enabled", {
      is: true,
      then: yup
        .number()
        .min(0.01, () => t("validationMessages.minNumber", { number: 0.01 }))
        .max(100, () => t("validationMessages.maxNumber", { number: 100 }))
        .required(),
      otherwise: yup.number().nullable(),
    }),
  }),
  subscription: yup.object().shape({
    enabled: yup.boolean().required(),
    fee: yup.number().when("enabled", {
      is: true,
      then: yup.number().required(),
      otherwise: yup.number().nullable(),
    }),
  }),
});

export const EcommerceCreditsSchema = yup.object().shape({
  amount: yup.number().when("$action", {
    is: (action: string) => action === "GIVE",
    then: yup.number().required().min(0.001).max(1000000).positive().maxDigitsAfterDecimal(3),
    otherwise: yup.number().notRequired(),
  }),
  mode: yup.string().oneOf(["all_users", "branch", "group", "user"]).required(),
  entity_id: yup
    .number()
    .nullable()
    .when("mode", {
      is: (mode: ModeType) => mode !== "all_users",
      then: yup.number().required(),
    }),
});
