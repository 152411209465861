import React, { FC, memo } from "react";
import { AvailabilityText } from "@components/ReusableComponents";
// TODO: Fix imports when new ToC will become the default one
import ToC from "@components/ToC/new/ToC";
import { Availability } from "types/entities/Unit";

type UnitListTitleProps = {
  name: string;
  isSection: boolean;
  availability: Availability | null;
};

const UnitListTitle: FC<UnitListTitleProps> = ({ name, isSection, availability }) => {
  if (!isSection) return <>{name}</>;
  const { delay, absolute_delay } = availability ?? {};
  // absolute_delay corresponds to the section's specific delay && delay corresponds to the total delay
  // both of these have to be true to show the section's availability
  const showAvailability = absolute_delay && delay;

  return (
    <ToC.ListTitle text={name}>
      {showAvailability && <AvailabilityText delay={delay} weight="400" fontSize="xs" />}
    </ToC.ListTitle>
  );
};

export default memo(UnitListTitle);
