import { css } from "@emotion/react";

export const TalentCraftStyles = () => css`
  height: 100%;
  width: 100%;

  .talent-craft-content {
    height: 100%;
    width: 100%;
    border: none;
  }
`;
