import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Checkbox, Radio } from "@epignosis_llc/gnosis";
import { mutlipleChoiceContainer } from "./styles";
import { MultipleChoiceQuestion } from "types/entities";

type MultipleChoiceContainerProps = {
  questionEntry: MultipleChoiceQuestion;
  userAnswers: string[];
  onAnswersChange: (answers: string[]) => void;
};

const MultipleChoiceContainer: FC<MultipleChoiceContainerProps> = ({
  questionEntry,
  userAnswers,
  onAnswersChange,
}) => {
  const answers = questionEntry.answers.possible;
  const hasMultipleAnswers = questionEntry.multiple_answers;
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number[]>([]);

  //The initial mapping of the answers
  useEffect(() => {
    if (!selectedAnswerIndex.length && userAnswers.length) {
      const initialAnswerIndices = userAnswers.reduce((array, item) => {
        const index = answers.findIndex((answer) => answer === item);
        if (index > -1) array.push(index);
        return array;
      }, [] as number[]);

      setSelectedAnswerIndex(initialAnswerIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedValueID = parseInt(e.target.name);

    const newUserAnswersIDs = selectedAnswerIndex.includes(selectedValueID)
      ? selectedAnswerIndex.filter((value) => value !== selectedValueID)
      : [...selectedAnswerIndex, selectedValueID];

    const resultArr = newUserAnswersIDs.map((i) => answers[i]);

    setSelectedAnswerIndex(newUserAnswersIDs);
    onAnswersChange(resultArr);
  };

  const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedValue = e.target.value;
    setSelectedAnswerIndex([parseInt(e.target.name)]);
    onAnswersChange([selectedValue]);
  };

  return (
    <form css={mutlipleChoiceContainer}>
      <div className="answers-content-container">
        {Object.values(answers).map((answer, i) => (
          <div key={i} className="answer">
            {hasMultipleAnswers ? (
              <Checkbox
                id={i.toString()}
                label={answer}
                name={i.toString()}
                value={answer}
                onChange={handleCheckboxChange}
                checked={selectedAnswerIndex.includes(i)}
              />
            ) : (
              <Radio
                id={i.toString()}
                checked={selectedAnswerIndex.includes(i)}
                label={answer}
                name={i.toString()}
                value={answer}
                onChange={handleRadioButtonChange}
              />
            )}
          </div>
        ))}
      </div>
    </form>
  );
};

export default MultipleChoiceContainer;
