import React, { FC, useRef, useEffect } from "react";
import { m } from "framer-motion";
import { useTheme } from "@emotion/react";
import { Text } from "@epignosis_llc/gnosis";
import { timeLimitBarContainer } from "./styles";
import { secondsToTime } from "@utils/helpers";

export type TimeLimitBarProps = {
  initialTime: number;
  currentTime: number;
  text: string;
  color?: "success" | "danger";
  children?: never;
};

const TimeLimitBar: FC<TimeLimitBarProps> = ({
  initialTime,
  currentTime,
  text,
  color = "success",
}) => {
  const { timeLimitBar } = useTheme();
  const percentage = (currentTime / initialTime) * 100;
  const bgColor = useRef(timeLimitBar.background);

  useEffect(() => {
    if (percentage <= 25) {
      bgColor.current =
        color === "success" ? timeLimitBar.sucessBackground : timeLimitBar.dangerBackground;
    } else {
      bgColor.current = timeLimitBar.background;
    }
  });

  return (
    <div css={timeLimitBarContainer}>
      <div className="time-limit-bar-wrapper">
        <div className="timestamp">
          <Text fontSize="xs" weight="700" className="duration-text">
            {text}:
          </Text>
          <Text fontSize="xs"> {secondsToTime(currentTime, true)}</Text>
        </div>
        <div className="barWrapper">
          <m.div
            className="bar"
            initial={false}
            animate={{
              background: bgColor.current,
              width: `${percentage}%`,
              borderRadius: "12px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeLimitBar;
