import { useAuth } from "@hooks";
import { useConfigurationStore } from "@stores";

export const useIsExternalView = (): boolean => {
  const { isAuthenticated } = useAuth();
  const { domainSettings } = useConfigurationStore();

  return !isAuthenticated && Boolean(domainSettings?.external_catalog);
};

export default useIsExternalView;
