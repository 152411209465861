import HttpClient from "./HttpClient";
import {
  IltSessionsRes,
  PrintAttendanceSheetRes,
  SingleIltSessionRes,
  UnenrolledUserRes,
} from "types/responses";
import { ENDPOINTS } from "./endpoints";

export const getIltSessions = async (queryStr = ""): Promise<IltSessionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.sessions}${queryStr}`);

  return res.data;
};

export const getIltSession = async (
  unitId: string,
  userId: string,
): Promise<SingleIltSessionRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.getSingle(unitId, userId)}`);

  return res.data;
};

export const getUnenrolledUsers = async (
  courseId: string,
  queryStr = "",
): Promise<UnenrolledUserRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.getUnenrolledUsers(courseId)}${queryStr}`);

  return res.data;
};

export const getIltSessionsUsers = async (
  sessionId: string,
  queryStr = "",
): Promise<IltSessionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.sessionsUsers(sessionId)}${queryStr}`);

  return res.data;
};

export const postAttendanceSheet = async (
  sessionId: string,
  columns: string[],
  isRoot: boolean,
): Promise<PrintAttendanceSheetRes> => {
  const body = {
    columns,
    add_children_pages: isRoot,
  };

  const res = await HttpClient.post(`${ENDPOINTS.ilts.printAttendance(sessionId)}`, body);

  return res.data;
};
