import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { defaultFooterContainer } from "./styles";
import FooterWrapper from "./components/FooterWrapper";
import { TestUnit } from "types/entities";
import { errorNotification, getQuestionLink } from "@utils/helpers";
import { MyUnitRes } from "types/responses";
import { SurveyUnit } from "types/entities/Survey";
import { FooterButton } from "@components/ReusableComponents";
import { useApplyTranslations, useRtlArrowIcons } from "@hooks";

type QuestionFooterProps = {
  unitRes: MyUnitRes;
  next_id: number | null;
  prev_id: number | null;
  userAnwsersEmpty: boolean;
  isSubmitting: boolean;
  canNavigate: boolean;
  onSubmit: () => void;
  isPublic?: boolean;
};

type UnitRouterParams = {
  courseId: string;
  unitId: string;
  questionId: string;
};

type QuestionUnitType = "survey" | "test";

const QuestionFooter: FC<QuestionFooterProps> = ({
  unitRes,
  next_id,
  prev_id,
  userAnwsersEmpty,
  isSubmitting,
  canNavigate,
  onSubmit,
  isPublic = false,
}) => {
  const { t } = useApplyTranslations();
  const { courseId, unitId, questionId } = useParams() as UnitRouterParams;
  const unit = unitRes?._data as TestUnit | SurveyUnit;
  const { unanswered_questions, question_ids } = unitRes._meta;
  const showPrevButton = canNavigate;
  const nextButtonText = next_id
    ? t("general.submitAnswer")
    : t(unit.type === "survey" ? "survey.submitSurvey" : "test.submitTest");
  const isNextButtonDisabled =
    unit.type === "survey" && !unit.survey_options?.mandatory_answers ? false : userAnwsersEmpty;
  const Icon = useRtlArrowIcons();

  const getBtnClassNames = (isHidden: boolean): string =>
    classNames({
      "btn-nav": true,
      hidden: isHidden,
    });

  const goToPrevQuestionLink =
    prev_id && canNavigate
      ? getQuestionLink(
          unit.type as QuestionUnitType,
          {
            courseId,
            unitId,
            questionId: prev_id?.toString(),
          },
          isPublic,
        )
      : "#";

  const handleSubmit = (): void => {
    if (unit.type === "survey") {
      if (
        (unit.survey_options.mandatory_answers && !userAnwsersEmpty) ||
        !unit.survey_options.mandatory_answers
      ) {
        onSubmit();
      }
    }

    if (unit.type === "test" && !userAnwsersEmpty) {
      // If we are on the last question check for unanswered questions
      if (next_id === null) {
        const incompleteQuestions = unanswered_questions
          .filter((question) => question !== parseInt(questionId))
          .map((question) => question_ids.indexOf(question) + 1);

        incompleteQuestions.length === 0
          ? onSubmit()
          : errorNotification(
              t("test.youHaveNotCompletedQuestions", {
                count: incompleteQuestions?.length,
                questions: incompleteQuestions.join(", "),
              }),
            );
      } else {
        onSubmit();
      }
    }
  };

  return (
    <FooterWrapper>
      <div css={defaultFooterContainer}>
        {showPrevButton && (
          <Button
            as={Link}
            to={goToPrevQuestionLink}
            color="secondary"
            noGutters
            className={getBtnClassNames(!prev_id)}
            aria-label="Go to previous unit"
          >
            <Icon height={24} />
          </Button>
        )}

        <FooterButton
          unit={unit}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          buttonText={nextButtonText}
          disabled={isNextButtonDisabled}
        />
      </div>
    </FooterWrapper>
  );
};

export default QuestionFooter;
