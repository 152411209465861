import React, { FC, useRef } from "react";
import { SerializedStyles } from "@emotion/utils";
import { VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import { Text, Heading, Tooltip, Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { tocHeader } from "./styles";
import { StatusTag, Link } from "@components/ReusableComponents";
import { useIsOverflow } from "@hooks";
import { TypographyLevels } from "@styles/utils";
import classNames from "classnames";

export type HeaderProps = FC<{
  title: string;
  titleSize?: TypographyLevels;
  titleLink?: string;
  imageUrl?: string;
  imageAlt?: string;
  dropdownOptions?: DropdownItem[];
  isInactive?: boolean;
  isSelected?: boolean;
  backLink?: {
    href: string;
    text: string;
  };
  onDropdownOptionSelect?: (item: DropdownItem) => void;
}>;

const headerContainerClasses = (isSelected: boolean): string =>
  classNames({
    "header-container": true,
    "is-selected": isSelected,
  });

const Header: HeaderProps = ({
  title,
  titleSize = "2xl",
  titleLink = "#",
  imageUrl,
  imageAlt = "Title image",
  backLink,
  dropdownOptions,
  children,
  isInactive = false,
  isSelected = false,
  onDropdownOptionSelect,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);

  const handleDropdownSelect = (item: DropdownItem): void => {
    onDropdownOptionSelect && onDropdownOptionSelect(item);
  };

  return (
    <header css={(theme): SerializedStyles => tocHeader(theme, Boolean(children))}>
      {backLink && (
        <Link to={backLink.href} className="back-link-wrapper">
          <Text fontSize="md">{backLink.text}</Text>
        </Link>
      )}

      <div className={headerContainerClasses(isSelected)}>
        <div className="content-container">
          {imageUrl && <img src={imageUrl} alt={imageAlt} />}

          <Tooltip content={title} disabled={!isOverflow}>
            <span className="title" ref={ref}>
              {titleLink ? (
                <Link to={titleLink} color="secondary">
                  <Heading size={titleSize}>{title}</Heading>
                </Link>
              ) : (
                <Heading size={titleSize}>{title}</Heading>
              )}
            </span>
          </Tooltip>
        </div>

        <div className="actions-container">
          {isInactive && <StatusTag />}

          {dropdownOptions && (
            <Dropdown
              list={dropdownOptions}
              placement="bottom-end"
              onListItemSelect={handleDropdownSelect}
            >
              <Button variant="ghost" noGutters as="div">
                <VerticalEllipsisSVG height={16} />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>

      {children}
    </header>
  );
};

export default Header;
