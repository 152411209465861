// Packages or third-party libraries
import React, { FC, useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Button, DropdownItem, Row, Select, TableHandlers, Tooltip } from "@epignosis_llc/gnosis";
import { PlusIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { selectedSessionStyles } from "./styles";

// Components
import { CustomTableActions, MassActionGradingDrawer, ResetModal } from "@components";
import { buildEmptyStateProps, emptyState } from "@components/CustomTable";
import { MassActionParam, MassActionType } from "@components/ReusableComponents";
import { SendMessageMassActionDrawer } from "@views/Users";
import IltSessionsTable from "./components/IltSessionsTable";
import GradingDrawer from "./components/GradingDrawer";

// Utils, hooks
import {
  applyQueryFilter,
  buildPaginatedSearchQuery,
  generalNotification,
  handleTableState,
  mapTableToSelectSorting,
  getMassActionsOptions,
} from "@utils/helpers";
import { useApplyTranslations, usePaginatedStateReducer } from "@hooks";
import { PaginatedState, PaginatedStateActions } from "@hooks/usePaginatedStateReducer";
import { handleAssignmentErrors } from "@errors/assignmentErrors";

// Other imports
import {
  resetSubmission,
  submissionsMassActions,
  submissionsMassActionsCount,
} from "@api/gradingHub";
import { getIltSessions, getIltSessionsUsers } from "@api/ilts";
import { putIltUnregister } from "@api/courses";
import { MassActionsProps } from "@components/CustomTable/types";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";
import { IltSession } from "types/entities";
import { IltTable, ResetIltSessionsArgs } from "./types";
import { NestedSelectOption } from "types/common";
import queryKeys from "@constants/queryKeys";
import {
  DEFAULT_FILTERS,
  DEFAULT_STATE,
  getIltSessionsFilterOptions,
  USERS_DEFAULT_STATE,
} from "./constants";

type IltSessionsProps = {
  tableType?: IltTable;
  sessionOptions?: NestedSelectOption[];
  selectedOption?: NestedSelectOption;
  handleSessionChange?: (option: NestedSelectOption) => void;
  openExtraModal?: () => void;
  onUnregisterSuccess?: () => void;
  onGradeSuccess?: () => void;
  onResetSuccess?: () => void;
  togglePrintAttendanceButton?: (shouldShowPrintAttendanceButton: boolean) => void;
};

const IltSessions: FC<IltSessionsProps> = ({
  tableType = "iltSessions",
  sessionOptions,
  selectedOption,
  handleSessionChange,
  openExtraModal,
  onUnregisterSuccess,
  onGradeSuccess,
  onResetSuccess,
  togglePrintAttendanceButton,
}) => {
  const { t } = useApplyTranslations();
  const defaultState = handleTableState(
    tableType === "iltSessions" ? DEFAULT_STATE : USERS_DEFAULT_STATE,
  );
  const queryClient = useQueryClient();

  const [submissionIds, setSubmissionIds] = useState<number[]>([]);
  const [resetInput, setResetInput] = useState(false);
  const [sessionsState, sessionsDispatch] = usePaginatedStateReducer(defaultState);
  const [selectedSession, setSelectedSession] = useState<IltSession | null>(null);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [isGradingDrawerOpen, setGradingDrawerOpen] = useState(false);
  const [isMessagingDrawerOpen, setMessagingDrawerOpen] = useState(false);
  const [isMassActionGradingDrawerOpen, setMassActionGradingDrawerOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const tableRef = React.useRef<TableHandlers>(null);

  const selectedSessionId = selectedOption?.id;
  const isSelectedSessionLinked = Boolean(selectedOption && selectedOption.level === 1);
  const showSessionSelect = sessionOptions && sessionOptions.length > 0 && selectedOption;
  const isUsersTable = tableType === "iltSessionsUsers";
  const drawerSize = isUsersTable ? "lg" : "md";
  const hasBackButton = isUsersTable;

  const { pagination, sorting: tableSorting, filters } = sessionsState;
  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });
  const queryKey = selectedSessionId ? queryKeys.ilts.sessionsUsers : queryKeys.ilts.sessions;

  const {
    status,
    data: iltSessions,
    error,
  } = useQuery(
    [queryKey, searchQuery, selectedSessionId],
    () =>
      selectedSessionId
        ? getIltSessionsUsers(selectedSessionId, searchQuery)
        : getIltSessions(searchQuery),
    {
      select: (sessions) => ({
        data: sessions._data,
        pagination: sessions._meta?.pagination,
        hasMaxCapacityReached: sessions._meta?.max_capacity_reached,
      }),
      onSuccess(data) {
        togglePrintAttendanceButton?.(Boolean(data.data.length));
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const handleRowSelect = (rows: Row[]): void => {
    setSubmissionIds(rows.map((course) => Number(course.id)));
  };

  const handleSortingChanged = (sorting: PaginatedState["sorting"]): void => {
    sessionsDispatch({ type: PaginatedStateActions.sorting, payload: { sorting } });
  };

  const handlePaginationPageChange = (value: number): void => {
    sessionsDispatch({ type: PaginatedStateActions.paginationPage, payload: { number: value } });
  };

  const handlePaginationPageSizeChange = (value: number): void => {
    sessionsDispatch({
      type: PaginatedStateActions.paginationPageSize,
      payload: { size: value },
    });
  };

  const handleSearchChanged = (searchValue: string): void => {
    cleanState();
    setResetInput(false);
    const filter = { key: "[keyword][like]", value: searchValue };
    const newFilters = applyQueryFilter({ filters, filter });

    sessionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFiltersChanged = ({ category, value }: DropdownItem): void => {
    cleanState();
    const filter = { key: category as string, value: value as string };
    const newFilters = applyQueryFilter({ filters: [...filters], filter });

    sessionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFilterRemove = ({ category, value }: DropdownItem): void => {
    cleanState();
    const newFilters = filters.filter((item) => !(category === item.key && value === item.value));

    sessionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleRestoreDefault = (): void => {
    sessionsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: DEFAULT_FILTERS },
    });
    setResetInput(true);
  };

  const onGrade = (subId: string, shouldOpenSessionTab?: boolean): void => {
    setTabIndex(shouldOpenSessionTab ? 0 : 1);
    const submission = iltSessions?.data.find(({ id }) => id?.toString() === subId.toString());

    if (submission && submission.policies.can_grade && !isSelectedSessionLinked) {
      setSelectedSession(submission);
      setGradingDrawerOpen(true);
    }
  };

  const onMessage = (subId: string): void => {
    const session = iltSessions?.data.find(({ id }) => id?.toString() === subId.toString());

    if (session) {
      setSelectedSession(session);
      setMessagingDrawerOpen(true);
    }
  };

  const openResetModal = (subId: string): void => {
    const submission = iltSessions?.data.find(({ id }) => id.toString() === subId);

    if (submission) {
      setSelectedSession(submission);
      setResetModalOpen(true);
    }
  };

  const handleUnregister = (userId: string): void => {
    if (selectedSessionId) unregisterMutation({ sessionId: selectedSessionId, userId });
  };

  const { mutate: unregisterMutation } = useMutation(
    ({ sessionId, userId }: { sessionId: string; userId: string }) =>
      putIltUnregister(sessionId, userId),
    {
      onSuccess: async () => {
        invalidateSessionsTable();
        onUnregisterSuccess && onUnregisterSuccess();
      },
      onError: () => {
        generalNotification("error", <p>{t("ilt.removeFromSessionUnsuccessful")}</p>);
      },
    },
  );

  const handleDrawerClose = (): void => {
    setMassActionGradingDrawerOpen(false);
    setGradingDrawerOpen(false);
    setMessagingDrawerOpen(false);
    setSelectedSession(null);
  };

  const handleGradeSuccess = (): void => {
    invalidateSessionsTable();
    onGradeSuccess && onGradeSuccess();
  };

  const { hasMaxCapacityReached } = iltSessions ?? {};

  const tableStatus = { status, error };

  const emptyStateProps = buildEmptyStateProps({
    filters,
    tableType,
    isDrawer: false,
    handleRestoreDefault,
    permission: true, // required true to show empty state info
    opensDrawer: !isSelectedSessionLinked,
    onClick: openExtraModal,
  });

  const invalidateSessionsTable = (): void => {
    queryClient.invalidateQueries([queryKey, searchQuery]);
    queryClient.invalidateQueries([queryKeys.gradingHub.pendingCount]);
  };

  const { mutate: resetMutation } = useMutation(
    [queryKeys.gradingHub.resetSubmission],
    (data: ResetIltSessionsArgs) => resetSubmission(data.unitId, data.userId),
    {
      onSettled: () => {
        invalidateSessionsTable();
        onResetSuccess && onResetSuccess();
      },
      onSuccess: () => {
        generalNotification("success", <p>{t("assignment.statusUpdatedSuccesfully")}</p>);
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const handleOnConfirmResetModal = (): void => {
    if (!selectedSession) return;

    const unitId = selectedSession.unit.id.toString();
    const userId = selectedSession.user.id.toString();

    resetMutation({ unitId, userId });
  };

  const closeResetModal = (): void => {
    setSelectedSession(null);
    setResetModalOpen(false);
  };

  // Mass actions
  const countRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<CountMassActionResponse> => {
      return submissionsMassActionsCount(type, { unit_progress_ids: submissionIds, ...data });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(submissionIds)],
  );

  const massActionRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<MassActionResultResponse> => {
      return submissionsMassActions(type, { unit_progress_ids: submissionIds, ...data });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(submissionIds)],
  );

  const cleanState = (): void => {
    setSubmissionIds([]);
    tableRef.current?.resetSelected();
  };

  const toggleGradeMassActionDrawer = (): void => {
    setMassActionGradingDrawerOpen((v) => !v);
  };

  const massActionsProps: MassActionsProps = {
    allowMassActions: true,
    massActionsOptions: getMassActionsOptions(),
    originTableName: "assignments",
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions: invalidateSessionsTable,
    toggleMassActionDrawer: (): void => setMessagingDrawerOpen((v) => !v),
    toggleGradeMassActionDrawer,
    selectedRows: submissionIds.length,
  };

  const selectedUsers = iltSessions
    ? iltSessions.data
        .filter((session) => submissionIds.includes(session.id))
        .map((session) => session.user)
    : [];

  const uniqueUserIds = [...new Set(selectedUsers.map((user) => user.id))];

  const handleSelectedSessionChange = (option: NestedSelectOption): void => {
    handleSessionChange?.(option);
  };

  return (
    <>
      {showSessionSelect && (
        <div css={selectedSessionStyles}>
          <Select
            id="selected-session"
            options={sessionOptions}
            minWidth="502px"
            maxWidth="502px"
            value={selectedOption}
            onChange={(option): void => handleSelectedSessionChange(option as NestedSelectOption)}
          />
        </div>
      )}

      <CustomTableActions
        filterDropdownOptions={getIltSessionsFilterOptions()}
        searchValue={defaultState.searchValue}
        tableHasData={Boolean(iltSessions?.data.length)}
        selectedFilters={filters}
        resetInput={resetInput}
        onSearchChange={handleSearchChanged}
        onFilterSelect={handleFiltersChanged}
        onFilterRemove={handleFilterRemove}
        areFiltersCategorized={false}
        massActionsProps={massActionsProps}
      >
        {openExtraModal && !isSelectedSessionLinked && (
          <Tooltip
            content={t("ilt.sessionReachedMaximumCapacity")}
            disabled={!hasMaxCapacityReached}
          >
            <Button
              noGutters
              iconBefore={PlusIconSVG}
              variant="link"
              onClick={openExtraModal}
              data-testid="add-user-to-ilt-session"
              disabled={hasMaxCapacityReached}
            >
              {t("users.addNew")}
            </Button>
          </Tooltip>
        )}
      </CustomTableActions>

      <IltSessionsTable
        data={iltSessions?.data ?? []}
        emptyState={emptyState(emptyStateProps)}
        paginationRes={iltSessions?.pagination}
        state={sessionsState}
        tableStatus={tableStatus}
        tableType={tableType}
        isSelectedSessionLinked={isSelectedSessionLinked}
        onSortingChanged={handleSortingChanged}
        onPageChange={handlePaginationPageChange}
        onPageSizeChange={handlePaginationPageSizeChange}
        onRowSelect={handleRowSelect}
        onGrade={onGrade}
        onMessage={onMessage}
        onReset={openResetModal}
        onUnregister={handleUnregister}
        tableRef={tableRef}
      />

      {isGradingDrawerOpen && selectedSession && (
        <GradingDrawer
          key={selectedSession.id}
          tabIndex={tabIndex}
          ILTSession={selectedSession}
          isDrawerOpen={isGradingDrawerOpen}
          size={drawerSize}
          hasBackButton={hasBackButton}
          onCloseDrawer={handleDrawerClose}
          onSuccess={handleGradeSuccess}
        />
      )}

      {isMessagingDrawerOpen && (
        <SendMessageMassActionDrawer
          isDrawerOpen={isMessagingDrawerOpen}
          userIds={selectedSession ? [selectedSession.user.id] : uniqueUserIds}
          size={drawerSize}
          hasBackButton={hasBackButton}
          onClose={handleDrawerClose}
        />
      )}

      {isResetModalOpen && (
        <ResetModal
          type="session"
          selectedSession={selectedSession}
          isResetModalOpen={isResetModalOpen}
          handleOnConfirmResetModal={handleOnConfirmResetModal}
          closeResetModal={closeResetModal}
        />
      )}

      {isMassActionGradingDrawerOpen && (
        <MassActionGradingDrawer
          isDrawerOpen={isMassActionGradingDrawerOpen}
          title={t("users.massActions.massGradeUsers")}
          countRequest={countRequest}
          massActionRequest={massActionRequest}
          size={drawerSize}
          hasBackButton={hasBackButton}
          onCloseDrawer={handleDrawerClose}
          invalidateSubmissionTable={invalidateSessionsTable}
          cleanState={cleanState}
        />
      )}
    </>
  );
};

export default IltSessions;
