import React, { FC } from "react";
import TopNav from "@views/Unit/components/TopNav";
import { Course, MyUnit, TestUnit } from "types/entities";

export type NavbarProps = FC<{
  unit: MyUnit | TestUnit;
  course: Course;
}>;

const Navbar: NavbarProps = ({ unit, course }) => (
  <TopNav unit={unit} courseProgress={course?.progress} />
);

export default Navbar;
