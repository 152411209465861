export const FONTAWESOME_KIT_URL = "https://kit.fontawesome.com/5bd730e918.js";

export const DEFAULT_CONFIGURATION = {
  attribution: false,
  iconsTemplate: "my_fa",
  imageInsertButtons: ["imageBack", "|", "imageByURL", "uploadImageButton", "insertImagesDropdown"],
  imageUploadRemoteUrls: false,
  key: "aLF3c1A7D6A4D3E3B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2B5D6A3D3E4D2F2==",
  listAdvancedTypes: false,
  linkInsertButtons: ["linkBack"],
  zIndex: 1,
  fontFamily: {
    // Default font
    "Mulish, Arial, sans-serif": "Mulish",
    // Other fonts
    "Roboto, sans-serif": "Roboto",
    "Open Sans, sans-serif": "Open Sans",
    "Helvetica, Arial, sans-serif": "Helvetica", // web-safe font
    "Arial, Helvetica, sans-serif": "Arial", // web-safe font
    "Montserrat, sans-serif": "Montserrat",
    "Lato, sans-serif": "Lato",
    "Source Sans Pro, sans-serif": "Source Sans Pro",
    "Nunito, sans-serif": "Nunito",
    "PT Sans, sans-serif": "PT Sans",
    "Poppins, sans-serif": "Poppins",
    "Georgia, serif": "Georgia", // web-safe font
    "Oswald, sans-serif": "Oswald",
    "Verdana, Geneva, sans-serif": "Verdana", // web-safe font
    "Rubik, sans-serif": "Rubik",
    "Lobster Two, sans-serif": "Lobster Two",
  },
  linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
  imageEditButtons: [
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "imageLink",
    "linkEdit",
    "linkRemove",
    "imageDisplay",
    "imageAlt",
  ],
  tableEditButtons: [
    "tableRemove",
    "tableRows",
    "tableColumns",
    "tableCellBackground",
    "tableCellHorizontalAlign",
  ],
  fontSize: ["8", "9", "10", "11", "12", "14", "16", "18", "24", "30", "36", "48", "60"],
  quickInsertButtons: ["uploadImageButton", "table", "ul", "ol", "hr", "insertSmartTagButton"],
};
