import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["unitsToCNew"] => {
  return {
    backgroundColor: colors.secondary.lighter,
    borderColor: colors.secondary.lighter,
    header: {
      backgroundColor: colors.white,
    },
    top: {
      backgroundColor: colors.primary.darker,
      AIButton: Color(colors.primary.lightest).alpha(0.25).string(),
      baseBackgroundColor: Color(colors.primary.darker).string(),
    },
    trigger: {
      backgroundColor: colors.secondary.lighter,
      borderColor: colors.white,
    },
    listItem: {
      activeColor: colors.primary.darker,
      disabledColor: colors.secondary.base,
      hoverColor: colors.white,
      borderColor: colors.secondary.lighter,
    },
  };
};
