import { css } from "@emotion/react";

export const modalFooter = css`
  text-align: start;

  .cancel-btn {
    margin-inline-start: 1rem;
  }
`;

export const modalBody = css`
  .prerequisites-info-wrapper {
    padding: 0 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .payments-info-text {
    padding: 2rem 1.25rem;
  }

  .payment-tabs {
    nav {
      padding-inline-start: 1.25rem;
    }
  }

  .redirection-info {
    svg {
      margin-inline-end: 0.25rem;
    }

    display: flex;
    align-items: flex-start;
    margin-inline-start: 4.375rem;
    padding: 0 1.25rem;
    margin-bottom: 1rem;
  }

  .invalid-coupon-container {
    padding: 0 1rem;
  }
`;

export const creditsContainer = ({ payments }) => css`
  padding: 2rem 1.25rem;

  .credits-info {
    margin-top: 0.75rem;
    margin-inline-start: 4.375rem;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .price,
    .previous-price {
      font-size: 18px;
    }

    .previous-price {
      color: ${payments.priceColor};
    }
  }

  .credits-num {
    color: ${payments.creditsValid};

    &.not-enough-credits {
      color: ${payments.creditsInvalid};
    }
  }
`;

export const paymentHeaderContainer = ({ payments }) => css`
  display: flex;
  align-items: flex-start;

  .course-img,
  .bundle-img {
    max-width: 55px;
    margin-inline-end: 0.75rem;
  }

  .course-img {
    border-radius: 3px;
  }

  .bundle-img {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
    background: ${payments.bundleBackground};
    padding: 0.5rem;
  }

  .course-info {
    .course-code {
      color: ${payments.code};
    }

    .course-category,
    .course-code,
    .course-name {
      word-break: break-all;
    }
  }
`;

export const couponContainer = ({ payments }) => css`
  margin-inline-start: 4.375rem;
  padding: 0 1.25rem;
  margin-bottom: 1rem;

  .input-field-wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }

  .input-container {
    max-width: 195px;
  }

  .invalid-coupon-text,
  .valid-coupon-text {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    svg {
      margin-inline-end: 0.5rem;
    }
  }

  .valid-coupon-text {
    margin-inline-start: 0.25rem;
    color: ${payments.couponValid};

    svg {
      color: ${payments.couponValid};
    }
  }
`;

export const courseRatingContainer = css`
  margin-top: 1.5rem;

  .rating-actions {
    display: flex;
    align-items: center;

    .star-wrapper {
      margin: 0 1rem;
    }

    .reset-btn {
      height: 2rem;
    }
  }

  .user-rating,
  .no-ratings-text {
    font-style: italic;
    display: block;
  }
`;

export const bundleListContainer = ({ dir }) => css`
  width: 100%;
  margin-top: 1rem;

  .dropdown-btn {
    svg {
      position: absolute;
      right: ${dir === "rtl" ? "auto" : 0};
      left: ${dir === "rtl" ? 0 : "auto"};
      margin-inline-end: 0.5rem;
      height: 1.75rem;
      fill: currentColor;
    }
  }

  .dropdown {
    width: 100%;
  }
`;

export const subscriptionModalBody = css`
  padding: 0;

  .body-wrapper {
    margin-inline-start: 4.375rem;

    form {
      padding: 0;
      margin: 1rem 0;
    }

    .subscription-info {
      margin-top: 1rem;
    }

    .redirection-info {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      padding-inline-end: 1.25rem;
    }

    .cancel-info {
      margin-bottom: 1rem;
      font-style: italic;
    }
  }
`;

export const subscriptionModalHeader = css`
  padding-bottom: 0.75rem;
  border: 0;
`;

export const courseThumbnailContainer = ({ courseOverview }) => css`
  position: relative;

  .thumbnail-image {
    width: 100%;
    max-height: 439px;
    aspect-ratio: 3/2;
  }

  .hover-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${courseOverview.thumbnailHover};

    svg {
      height: 16px;
      color: ${courseOverview.thumbnailSvgColor};
    }
  }
`;
