import React, { FC, ReactNode } from "react";
import { unitPageLayoutContent } from "../styles";
import Navbar from "./Navbar";
import { Course, MyUnit, TestUnit } from "types/entities";

export type ContentProps = FC<{
  unit: MyUnit | TestUnit;
  course: Course;
  children: ReactNode;
}>;

const Content: ContentProps = ({ unit, course, children }) => {
  return (
    <div css={unitPageLayoutContent}>
      <Navbar unit={unit} course={course} />
      {children}
    </div>
  );
};

export default Content;
