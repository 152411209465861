import React, { FC } from "react";
import { Radio, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import classNames from "classnames";

type LikertScaleOptionProps = {
  id: string;
  index: number;
  label: string;
  value: string;
  isChecked: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  extraInfo?: JSX.Element;
  customBreakpoint?: boolean;
  onChange?: () => void;
};

const optionStyles = (readOnly: boolean, disabled: boolean): string =>
  classNames({
    "likert-option": true,
    "read-only": readOnly,
    disabled: disabled,
  });

const LikertScaleOption: FC<LikertScaleOptionProps> = ({
  id,
  index,
  label,
  value,
  isChecked,
  readOnly = false,
  disabled = false,
  extraInfo,
  customBreakpoint = false,
  onChange,
}) => {
  const { md, lg } = useResponsive();
  const name = `${id}-${index}`;

  const breakpoint = customBreakpoint ? lg : md;

  return (
    <li className={optionStyles(readOnly, disabled)}>
      {extraInfo && extraInfo}
      <div className="likert-answer">
        <Radio
          id={name}
          label={!breakpoint ? label : ""}
          value={value}
          name={name}
          checked={isChecked}
          readOnly={readOnly}
          disabled={isChecked && disabled}
          onChange={(): void => {
            onChange && onChange();
          }}
        />
      </div>

      {breakpoint && (
        <Text as="div" fontSize="sm" className="option-label">
          {label}
        </Text>
      )}
    </li>
  );
};

export default LikertScaleOption;
