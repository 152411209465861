import React, { FC, ReactNode } from "react";
import { Text } from "@epignosis_llc/gnosis";

import { emptyStateContainer } from "./styles";

type EmptyStateProps = {
  icon?: JSX.Element;
  title: string | JSX.Element;
  info?: string;
  footer?: ReactNode;
};

const EmptyState: FC<EmptyStateProps> = ({ icon: Icon, title, info, footer }) => {
  return (
    <div css={emptyStateContainer}>
      <div className="empty-state-container">
        {Icon && <div className="empty-state-icon">{Icon}</div>}

        <div className="empty-state-body">
          {typeof title === "string" ? (
            <Text fontSize="lg" as="div" weight="700">
              {title}
            </Text>
          ) : (
            title
          )}

          {info && (
            <Text fontSize="sm" as="div" weight="400" className="empty-state-description">
              {info}
            </Text>
          )}
        </div>

        {footer && <footer className="empty-state-footer">{footer}</footer>}
      </div>
    </div>
  );
};

export default EmptyState;
