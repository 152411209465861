// Packages or third-party libraries
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { usePrevious } from "ahooks";
import { AxiosError } from "axios";

// Components
import { DragAndDropArea } from "@components";
import UnitCreateDropdown from "../UnitCreateDropdown/UnitCreateDropdown";
import UnitList from "./components/TocContent/components/UnitList";
import SidebarEmptyState from "./components/TocContent/components/SidebarEmptyState";
import TocContentWrapper from "./components/TocContent/TocContentWrapper";
// TODO: Fix imports when new ToC will become the default one
import ToCContainer from "@components/ToC/new/ToCContainer";
import ToC from "@components/ToC/new/ToC";
import ToCTop from "./components/TocTop/ToCTop";
import ToCHeader from "./components/TocHeader/ToCHeader";

// Utils, stores, hooks
import { generalNotification } from "@utils/helpers";
import { getFilesValidation } from "@components/ReusableComponents/UploadArea/helpers";
import { flatsUnitsReducer } from "../../reducers";
import { useApplyTranslations } from "@hooks";
import { usePostCourseUnit } from "@views/CourseEdit/hooks";
import { useUIStore } from "@stores";

// Other imports
import { SidebarContentProps } from "../../types";
import { MyUnit, Section } from "types/entities";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import { MAX_ALLOWED_FILES, validationTypes } from "./components/constants";
import queryKeys from "@constants/queryKeys";

const SidebarContent: FC<SidebarContentProps> = ({ course, sections }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { showUnitSidebar } = useUIStore();
  const { unitId, courseId } = useParams() as UnitEditRouterParams;
  const listWrapperRef = useRef<HTMLDivElement>(null);

  const { policies } = course;
  const canAddUnit = Boolean(policies?.can_add_unit);
  const mimeTypeAndFilesizeValidations = getFilesValidation(validationTypes);

  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  // a list of sections and their units in the same level, used to render the list of units
  const [flatUnits, setFlatUnits] = useState(() =>
    sections.reduce(flatsUnitsReducer, [] as Array<Section | MyUnit>),
  );
  const previousFlatUnits = usePrevious(flatUnits);
  const hasUnits = flatUnits.length > 0;

  const { mutate: postUnitFileMutation, isLoading: postUnitFileLoading } = usePostCourseUnit({
    courseId,
    options: {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.unitCreatedSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.units]);
        queryClient.invalidateQueries([queryKeys.courses.images, courseId]);
      },
      onError: (err: unknown) => {
        const error = err as AxiosError;
        generalNotification("error", <p>{error.response?.data._errors[0].title}</p>);
      },
    },
  });

  const handleFilesChanged = (files: File[]): void => {
    setDroppedAttachments(null);

    files.forEach((file) => {
      postUnitFileMutation({ file, isLastFile: false });
    });
  };

  const handleFilesDrop = (files: FileList): void => {
    const fileArray: File[] = Object.values(files);

    fileArray.map((file) => {
      postUnitFileMutation({ file, isLastFile: false });
    });
  };

  const handleFlatUnitsChange = (units: (Section | MyUnit)[]): void => {
    setFlatUnits(units);
  };

  const scrollToActiveItem = (): void => {
    // active unit will exists only on unit edit page
    if (listWrapperRef.current && unitId) {
      const activeListItem = listWrapperRef.current.querySelector(".unit-list-item.is-active");
      activeListItem?.scrollIntoView();
    }
  };

  useEffect(() => {
    setFlatUnits(sections.reduce(flatsUnitsReducer, [] as Array<Section | MyUnit>));
  }, [sections]);

  useLayoutEffect(() => {
    // initial render
    if (!previousFlatUnits) {
      scrollToActiveItem();
    } else {
      // when a new section or unit have been created, scroll to list's bottom
      if (flatUnits.length > previousFlatUnits.length) {
        listWrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }

    if (postUnitFileLoading) {
      listWrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatUnits, postUnitFileLoading]);

  return (
    <ToCContainer>
      <ToC>
        <ToCTop course={course} sections={sections} isOpen={showUnitSidebar} />
        <ToCHeader course={course} flatUnits={flatUnits} isOpen={showUnitSidebar} />
        <TocContentWrapper hasUnits={hasUnits}>
          <>
            <div id="course-content" className="outer-scrollable-container">
              <DragAndDropArea
                maxFiles={MAX_ALLOWED_FILES}
                mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
                onFilesDrop={handleFilesDrop}
                className="upload-prompt-area"
                shouldValidate={true}
                preventDrop={!canAddUnit || postUnitFileLoading || !showUnitSidebar}
                showRules
              >
                {hasUnits ? (
                  <UnitList
                    course={course}
                    flatUnits={flatUnits}
                    listWrapperRef={listWrapperRef}
                    postFileLoading={postUnitFileLoading}
                    showUnitSidebar={showUnitSidebar}
                    handleFlatUnitsChange={handleFlatUnitsChange}
                  />
                ) : (
                  <SidebarEmptyState
                    postFileLoading={postUnitFileLoading}
                    droppedAttachments={droppedAttachments}
                    canAddUnit={canAddUnit}
                    showUnitSidebar={showUnitSidebar}
                    handleFilesChanged={handleFilesChanged}
                  />
                )}
              </DragAndDropArea>
            </div>

            {/* Dropdown with all the units that can be created in the course */}
            {canAddUnit && showUnitSidebar && <UnitCreateDropdown course={course} />}
          </>
        </TocContentWrapper>
      </ToC>
    </ToCContainer>
  );
};

export default SidebarContent;
