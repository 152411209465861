import React from "react";
import { Text } from "@epignosis_llc/gnosis";
import { MAIN_BUTTON_TEXT } from "./constants";
import { capitalize, getButtonAvailabilityText } from "@utils/helpers";
import { t } from "@utils/i18n";
import { Course } from "types/entities";
import { Price } from "@components";
import { Price as PriceType } from "types/entities/Common";
import { Subscription } from "types/entities/Catalog";
import userRoles from "@constants/userRoles";

export const getIsButtonDisabled = (
  isSubscriptionEnabled: boolean,
  isCatalogCourseView: boolean,
  unlocksOnHigherLevel: boolean,
  availability: Course["availability"],
  role_in_course: string | undefined,
): boolean => {
  if (isCatalogCourseView && role_in_course === userRoles.INSTRUCTOR) return true;

  const isCourseAvailable = availability //if we are enrolled check availability, else the button is disabled only for level restrictions
    ? availability?.is_available
    : !unlocksOnHigherLevel;

  if (unlocksOnHigherLevel) return true;

  // If we are on catalog view and susbcriptions are active and the course is not available due to susbription cancellation, allow the user to susbribe
  if (
    !isCourseAvailable &&
    isCatalogCourseView &&
    availability?.reason === "cancelled" &&
    isSubscriptionEnabled
  )
    return false;

  return !isCourseAvailable;
};

export const getButtonText = ({
  isUserEnrolled,
  isCatalogCourseView,
  course,
  unlocksOnHigherLevel,
  hasPrice,
  isSubscriptionEnabled,
  userHasActiveSubscription,
  subscriptionPrice,
  isPublicCourse,
  isExternalView,
}: {
  isUserEnrolled: boolean;
  isCatalogCourseView: boolean;
  course: Course;
  unlocksOnHigherLevel: boolean;
  hasPrice: boolean | null;
  isSubscriptionEnabled: boolean;
  userHasActiveSubscription: boolean;
  subscriptionPrice: PriceType | undefined;
  isPublicCourse: boolean;
  isExternalView: boolean;
}): string | JSX.Element => {
  // If the course is public
  if (isPublicCourse && isExternalView) {
    return t("general.tryItForFree");
  }

  // If subscriptions are enabled and we are on catalog view
  if (isSubscriptionEnabled && isCatalogCourseView && !unlocksOnHigherLevel && hasPrice) {
    return userHasActiveSubscription ? (
      isUserEnrolled ? (
        t(MAIN_BUTTON_TEXT[course?.progress?.completion_status ?? ""])
      ) : (
        t("payments.subscriptions.addToSubscription")
      )
    ) : (
      <Text fontSize="sm" as="div" className="subscribe-btn-text">
        {t("general.subscribe")} (<Price value={subscriptionPrice?.amount ?? 0} />)
      </Text>
    );
  }

  // If user is enrolled and course is not available
  if (course.availability && !course.availability?.is_available) {
    return getButtonAvailabilityText(course);
  }

  // If we are on a catalog view, check level restrictions
  if (isCatalogCourseView) {
    const price = course.price as PriceType;
    const discounted_price = course.discounted_price;

    // We show the price only if the user is not enrolled and there is no level restriction
    if (hasPrice && !unlocksOnHigherLevel && !isUserEnrolled) {
      return (
        <Price
          value={discounted_price ? discounted_price.amount : price.amount}
          prevValue={discounted_price ? price.amount : undefined}
        />
      );
    }

    return isUserEnrolled
      ? t(MAIN_BUTTON_TEXT[course?.progress?.completion_status ?? ""])
      : unlocksOnHigherLevel
      ? t("restrictions.unlockedOnLevel", { level: course.level })
      : t("courseCatalog.getCourse");
  }

  return t(MAIN_BUTTON_TEXT[course?.progress?.completion_status ?? ""]);
};

export const getPaymentNotificationTranslation = (
  type: string,
  isUserEnrolled: boolean,
  processor: string,
  name: string,
): string => {
  if (type === "payment") {
    return isUserEnrolled
      ? t("payments.paymentSuccess", { name })
      : t("payments.paymentProcessing", { processor: capitalize(processor), name });
  }

  return t("payments.subscriptions.subscribedSuccessfully");
};

export const getSubsriptionInterval = (interval: Subscription["interval"]): string => {
  if (interval === 1) return t("general.month", { count: 1 });

  return t("general.yearLowercase", { count: 1 });
};

export const getSubscriptionInterval2 = (interval: Subscription["interval"]): string => {
  if (interval === 1) return t("general.monthly", { count: 1 });

  return t("general.annually", { count: 1 });
};

export const getShowButton = (
  isCatalogCourseView: boolean,
  isUserEnrolled: boolean,
  continueUnitID: number | null,
  availability: Course["availability"],
): boolean => {
  if (isCatalogCourseView && isUserEnrolled) {
    return (
      (!availability?.is_available && availability?.reason === "cancelled") ||
      Boolean(continueUnitID)
    );
  }

  return true;
};
