import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { certificateStyles } from "./styles";
import { formatUtcDate } from "@utils/helpers";
import { CourseCertificate } from "types/entities/Courses";
import { CertificateSVG, CertificateCompletedSVG } from "@assets/images";
import { useApplyTranslations } from "@hooks";
import { EditIconSVG } from "@epignosis_llc/gnosis/icons";
import { useUIStore } from "@stores";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

type CertificateProps = {
  acquiredCertificate: CourseCertificate | null;
  canEditOptions?: boolean;
};

const Certificate: FC<CertificateProps> = ({
  acquiredCertificate: acquiredCertificate,
  canEditOptions = false,
}) => {
  const { t } = useApplyTranslations();
  const { setCourseOptionsState } = useUIStore();
  const { expires_at = null, url } = acquiredCertificate ?? {};

  const handleEditCertificate = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Certificate,
      scrollId: courseOptionsIds.certificateOptions,
    });
  };

  return (
    <div css={certificateStyles}>
      <div className="certificate-container">
        <div className="certificate-image-container">
          {canEditOptions && (
            <div className="upload-overlay">
              <Button rounded onClick={handleEditCertificate}>
                <EditIconSVG height={32} />
              </Button>
              <Text fontSize="sm">{t("accountAndSettings.courseSettings.editCertificate")}</Text>
            </div>
          )}
          <div className="certificate-image">
            {acquiredCertificate ? <CertificateCompletedSVG /> : <CertificateSVG />}
          </div>
        </div>
        <div className="certificate-text-container">
          {acquiredCertificate ? (
            <>
              <Text fontSize="md" weight="700">
                {t("general.congratulations")}
              </Text>
              <Text fontSize="sm">
                {expires_at ? (
                  <Text
                    fontSize="sm"
                    as="div"
                    dangerouslySetInnerHTML={{
                      __html: t("myCourses.certificateExpires", {
                        date: `<strong>${formatUtcDate(expires_at)}</strong>`,
                      }),
                    }}
                  />
                ) : (
                  <Text
                    fontSize="sm"
                    as="div"
                    dangerouslySetInnerHTML={{
                      __html: t("myCourses.certificateNotExpires"),
                    }}
                  />
                )}
                <Text
                  fontSize="sm"
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: t("myCourses.certificateDownload", { url }),
                  }}
                />
              </Text>
            </>
          ) : (
            <>
              <Text fontSize="md" weight="700">
                {t("myCourses.awardsCertificate")}
              </Text>
              <Text fontSize="sm">{t("myCourses.completeToAcquire")}</Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
