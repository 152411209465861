import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const tocContainer = ({ unitsToC, units }) => css`
  width: 100%;
  border-inline-end: 2px solid ${unitsToC.borderColor};
  /* overflow: hidden; */

  .scrollable-container {
    overflow-y: auto;
    height: calc(100vh - 15rem);
    padding-inline-end: 2rem;
  }

  .header {
    height: calc(100vh - 16rem) !important;
  }

  .black-text {
    div {
      color: ${unitsToC.titleColor};
    }
  }

  .files-container {
    margin-top: 2rem;
  }

  .progress-bar {
    &.success {
      background-color: ${units.test.successColor};
    }

    &.error {
      background-color: ${units.test.errorColor};
    }

    div {
      justify-content: center;
      width: 100%;
    }

    &:after {
      background: transparent;
    }
  }
`;

export const tocDrawerHeaderContainer = css`
  display: inline-flex;
  align-items: center;

  ${mq["lg"]} {
    display: none;
  }
`;

export const tocDrawerBodyContainer = css`
  padding-bottom: 1rem;
  padding-inline: 1.5rem 2rem;

  &.course-edit {
    padding: 0;
  }

  .files-container {
    margin-top: 2rem;
  }
`;

export const tocTriggerContainer = () => css`
  &.is-open {
    .toc-trigger {
      inset-inline-start: 1rem;
    }
  }

  .toc-trigger {
    position: absolute;
    top: 0.5rem;
    inset-inline-start: 1rem;
    z-index: 99;

    ${mq["lg"]} {
      top: 1rem;
      inset-inline-start: calc(23rem - 42px);
    }
  }
`;
