import * as Sentry from "@sentry/react";
import { config } from "@config";
import { AxiosError } from "axios";
import {
  branchSwitchErrors,
  couponErrors,
  discussionErrors,
  newMessageErrors,
  noPermissionsError,
  updateProfileErrors,
  passwordChangeErrors,
  enrollmentErrors,
  paymentErrors,
  deleteThemeErrors,
  subscriptionErrors,
  createThemeErrors,
  branchesErrors,
  groupsErrors,
  autologinErrors,
  signUpErrors,
  signInErrors,
  userErrors,
  userCoursesErrors,
  userUnitsErrors,
  userBranchesErrors,
  userImpersonationErrors,
  joinGroupErrors,
  uploadFilesErrors,
  previewCertificateTemplateErrors,
  genericMassActionsErrors,
  domainNameErrors,
  customEmailErrors,
  customSSLErrors,
  languageOverridesErrors,
  CSRKeyErrors,
  customDomainNameErrors,
  autologinBranchErrors,
  affectedBranchesByMassActionsErrors,
  previewCertificateErrors,
  customUserFieldsErrors,
  userTypesErrors,
  cmsPagesErrors,
  timelineEventErrors,
  customReportsErrors,
  calendarEventErrors,
  groupUsersMassActionsErrors,
  cancelSubscriptionErrors,
  updateCreditCardErrors,
  reactivateSubscriptionErrors,
  genericErrors,
  downgradeErrors,
  markQuestionCorrectErrors,
  passwordCreateErrors,
  purchasePlanErrors,
  userActivationErrors,
  AICreditsErrors,
  branchCreateUpdateErrors,
  badgeSetUpdateErrors,
} from "./errorMessages";
import { generalNotification } from "@utils/helpers";
import { t } from "@utils/i18n";
export type HandledError = {
  status: number;
  id: string;
  errorMsg?: string;
};

type ItemWithExpiration = {
  value: string;
  expiry: number;
};

export const handledApiError = (
  error: AxiosError,
  errorsArray: HandledError[],
  hideNotification = false,
  defaultError: string,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  const status = error?.response?.status;
  const errorId = error?.response?.data._errors[0]?.id;

  //Check for invalid domain => redirect to given URL
  if (errorId === "service_unavailable.invalid_domain" || errorId === "not_found.invalid_domain") {
    const redirectionURL = `https://${error?.response?.data._meta?.redirect_url}`;
    window.location.replace(redirectionURL);
  }

  //We need to check in case of a validation_error
  const validationErrorId =
    errorId === "validation_error"
      ? error?.response?.data._errors[0]?.validation_errors[0]?.error_id
      : null;

  const foundError = errorsArray.find(
    (errorItem) =>
      errorItem.status === status &&
      (!validationErrorId ? errorItem.id === errorId : validationErrorId === errorItem.id),
  );

  if (!hideNotification) {
    const shownError = foundError?.errorMsg ? t(foundError?.errorMsg) : defaultError;
    shownError.length > 0 && generalNotification("error", shownError);
  }

  if (handleError) handleError(foundError ?? null, error);
};

export const handleUserCreateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, updateProfileErrors, true, "", handleError);
};

export const handleGroupsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, groupsErrors, hideNotification, "", handleError);
};

export const handleUpdateProfileErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, updateProfileErrors, hideNotification, "", handleError);
};

export const handleUserErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userErrors, hideNotification, "", handleError);
};

export const handleUploadFilesErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, uploadFilesErrors, hideNotification, "", handleError);
};

export const handleCouponErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, couponErrors, true, t("errors.coupons.couponInvalid"), handleError);
};

export const handleNoPermissionError = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, noPermissionsError, false, "", handleError);
};

export const handleBranchSwitchErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, branchSwitchErrors, false, "", handleError);
};

export const handleNewMessageErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, newMessageErrors, false, "", handleError);
};

export const handleDiscussionErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, discussionErrors, false, "", handleError);
};

export const handleSignUpErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, signUpErrors, hideNotification, "", handleError);
};

export const handleSignInErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, signInErrors, hideNotification, "", handleError);
};

export const handleImpersonationErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, userImpersonationErrors, hideNotification, "", handleError);
};

export const handleAutologinErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, autologinErrors, hideNotification, "", handleError);
};

export const handlePasswordChangeErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, passwordChangeErrors, hideNotification, "", handleError);
};

export const handlePasswordCreateErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, passwordCreateErrors, hideNotification, "", handleError);
};

export const handlePaymentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, paymentErrors, true, t("errors.coupons.couponInvalid"), handleError);
};

export const handleEnrollmentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, enrollmentErrors, false, "", handleError);
};

export const handleSubscriptionErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, subscriptionErrors, false, "", handleError);
};

export const handleCreateThemeErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, createThemeErrors, false, "", handleError);
};

export const handleDeleteThemeErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, deleteThemeErrors, false, "", handleError);
};

export const handleBranchesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, branchesErrors, false, "", handleError);
};

export const handleUserCoursesErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userCoursesErrors, hideNotification, "", handleError);
};

export const handleUserUnitsError = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userUnitsErrors, true, "", handleError);
};

export const handleUserBranchesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userBranchesErrors, false, "", handleError);
};

export const handleJoinGroupErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, joinGroupErrors, true, "", handleError);
};

export const handlePreviewCertificateTemplateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, previewCertificateTemplateErrors, false, "", handleError);
};

export const handlePortalSettingsErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, previewCertificateTemplateErrors, false, "", handleError);
};

export const handleDomainNameErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, domainNameErrors, false, "", handleError);
};

export const handleCustomEmailErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, customEmailErrors, false, "", handleError);
};

export const handleCustomSSLErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, customSSLErrors, false, "", handleError);
};

export const handleAutologinBranchErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, autologinBranchErrors, hideNotification, "", handleError);
};

export const handleGenericMassActionsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, genericMassActionsErrors, hideNotification, "", handleError);
};

export const handleGroupUsersMassActionsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, groupUsersMassActionsErrors, hideNotification, "", handleError);
};

export const handleCSRKeyErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, CSRKeyErrors, hideNotification, "", handleError);
};

export const handleCustomDomainNameErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, customDomainNameErrors, hideNotification, "", handleError);
};

export const handleLanguageOverridesErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, languageOverridesErrors, hideNotification, "", handleError);
};

export const handleBranchesMassActionsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, affectedBranchesByMassActionsErrors, hideNotification, "", handleError);
};

export const handlePreviewCertificateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, previewCertificateErrors, false, "", handleError);
};

export const handleCustomUserFieldsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, customUserFieldsErrors, hideNotification, "", handleError);
};

export const handleUserTypesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userTypesErrors, false, "", handleError);
};

export const handleCMSPagesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, cmsPagesErrors, false, "", handleError);
};

export const handleTimelineErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, timelineEventErrors, hideNotification, "", handleError);
};

export const handleCustomReportErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, customReportsErrors, hideNotification, "", handleError);
};

export const handleCalendarEventErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, calendarEventErrors, hideNotification, "", handleError);
};

export const handleCancelSubscriptionErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, cancelSubscriptionErrors, hideNotification, "", handleError);
};

export const handleDowngradeToFreeErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, downgradeErrors, hideNotification, "", handleError);
};

export const handleReactivateSubscriptionErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, reactivateSubscriptionErrors, hideNotification, "", handleError);
};

export const handleUpdateBillingAddressErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    cancelSubscriptionErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleUpdateCreditCardErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    updateCreditCardErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleInvoiceRecipientsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    genericErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleMarkQuestionCorrectErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    markQuestionCorrectErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handlePurchasePlanErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    purchasePlanErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleUserActivationErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    userActivationErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleBranchCreateUpdateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    branchCreateUpdateErrors,
    false,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleBadgeSetUpdateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    badgeSetUpdateErrors,
    false,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const setItemWithExpiration = (key: string, value: string, ttl: number): void => {
  const item: ItemWithExpiration = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiration = (key: string): string | null => {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item: ItemWithExpiration = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const errorBoundaryHandler = (
  error: Error,
  componentStack: { componentStack: string },
): void => {
  if (config.isStaging() || config.isProd()) {
    Sentry.captureException(error); // log error to sentry
  } else {
    // eslint-disable-next-line no-console
    console.error(error, componentStack);
  }
};

export const handleAICreditsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    AICreditsErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};
