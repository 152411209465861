import React, { FC } from "react";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { QuestionType, RichText } from "types/entities";
import { useApplyTranslations } from "@hooks";

type QuestionTitleProps = {
  type: QuestionType;
  text: RichText;
};

const QuestionTitle: FC<QuestionTitleProps> = ({ type, text }) => {
  const { t } = useApplyTranslations();
  const questionTypesSubtitles = {
    multiple_choice: {
      title: text.html,
      subtitle: t("test.multipleChoice"),
    },
    freetext: {
      title: text.html,
      subtitle: t("test.freeText"),
    },
    ordering: {
      title: text.html,
      subtitle: t("test.dragAndDrop"),
    },
    drag_and_drop: {
      title: text.html,
      subtitle: t("test.matchThePairs"),
    },
    fill_the_gap: {
      title: t("test.fillTheGaps"),
      subtitle: "",
    },
    likert_scale: {
      title: text.html,
      subtitle: t("test.likertScale"),
    },
  };

  const isTitlePlainText = type === QuestionType.FillTheGap || text.html === text.text;
  const { title, subtitle } = questionTypesSubtitles[type];

  return (
    <div className="title-container">
      <div className="title-content-container fr-view">
        {title && (
          <Text fontSize="sm" as="div" className="question-subtitle">
            {subtitle}
          </Text>
        )}

        {isTitlePlainText ? (
          <Heading
            as="h3"
            className="question-title"
            dangerouslySetInnerHTML={{ __html: title ?? subtitle }}
          />
        ) : (
          <div className="question-title" dangerouslySetInnerHTML={{ __html: title ?? subtitle }} />
        )}
      </div>
    </div>
  );
};

export default QuestionTitle;
