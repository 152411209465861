import React, { FC, Fragment, useEffect, useRef } from "react";
import classNames from "classnames";
import { groupBy, pipe, values, reverse, toString } from "ramda";
import UserItem from "./UserItem";
import { userStatsItem } from "./styles";
import { LeaderboardUser } from "types/entities";
import { useConfigurationStore } from "@stores";
import useIsScrolledIntoView from "@hooks/useIsScrolledIntoView";
import { SerializedStyles } from "@emotion/react";

export type UserStatsProps = {
  stats: LeaderboardUser[];
  isGamificationDrawer?: boolean;
  hasFooter?: boolean;
  title?: string;
};

const groupByScore = pipe<
  // TODO: replace any[] with the correct type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any[],
  Record<string, LeaderboardUser[]>,
  LeaderboardUser[][],
  LeaderboardUser[][]
>(
  groupBy((user) => toString(user.score)),
  values,
  reverse,
);

const userClassNames = (id: number, userId: number | undefined, isFixed = false): string =>
  classNames({
    "list-wrapper": true,
    "user-background": id === userId,
    "fixed-user": isFixed,
  });

const UserStats: FC<UserStatsProps> = ({
  stats,
  isGamificationDrawer,
  title,
  hasFooter = false,
}) => {
  const { userProfileData } = useConfigurationStore();
  const leadersGroupsByScore = groupByScore(stats);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parentElementRef: any = useRef(null);
  const currentUserRef = useRef(null);
  const isIntoView = useIsScrolledIntoView(currentUserRef, parentElementRef.current);

  useEffect(() => {
    if (isGamificationDrawer) {
      const gamificationDrawer = document.querySelector("#gamification-drawer");

      const parentElement =
        gamificationDrawer &&
        gamificationDrawer.querySelectorAll('[class*="gamification-container"]');

      if (parentElement) {
        parentElementRef.current = parentElement[0];
        const parentContainer = parentElement[0]?.parentElement;
        if (parentContainer) {
          parentContainer.style.overflowY = "hidden";
        }
      }
    }
  }, [isGamificationDrawer]);

  return (
    <ol
      css={(theme): SerializedStyles => userStatsItem(theme, { isGamificationDrawer, hasFooter })}
    >
      {leadersGroupsByScore.map((leaderGroup, index) =>
        leaderGroup.map((leaderboardUser) => {
          const { id, name } = leaderboardUser;

          return (
            <Fragment key={`${id}-${name}`}>
              <li
                ref={userProfileData?.id === id ? currentUserRef : null}
                className={userClassNames(id, userProfileData?.id)}
              >
                <UserItem title={title} leaderboardUser={leaderboardUser} index={index} />
              </li>

              {!isIntoView && id === userProfileData?.id && isGamificationDrawer && (
                <li className={userClassNames(id, userProfileData?.id, true)}>
                  <UserItem leaderboardUser={leaderboardUser} index={index} />
                </li>
              )}
            </Fragment>
          );
        }),
      )}
    </ol>
  );
};

export default UserStats;
